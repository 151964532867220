import React from "react";
import { Avatar, Caption, Text } from "@telegram-apps/telegram-ui";
import { IoCloseCircleOutline } from "react-icons/io5";
import styles from "./styles.module.scss";
import cx from "classnames";

const QuestHeroes = ({
  selectedHeroes,
  setIsSelect,
  quest,
  handleRemoveHero,
}) => {
  return (
    <>
      <Text className={styles.fadeIn}>
        {quest.status === "started" || quest.status === "finished"
          ? "Your heroes:"
          : `Choose your heroes:`}
      </Text>
      {!selectedHeroes.length && (
        <Avatar
          className={styles.avatar}
          onClick={() => setIsSelect(true)}
          size={48}
        >
          +
        </Avatar>
      )}
      {!!selectedHeroes.length &&
        selectedHeroes.map((hero) => (
          <div key={hero.id} className={cx(styles.selectedHero, styles.fadeIn)}>
            <Avatar
              size={48}
              src={hero.hero_preview_url}
              className={cx(styles.heroImg, styles.fadeIn)}
            >
              {quest.status !== "finished" &&
                quest.status !== "started" &&
                quest.status !== "finished" && (
                  <div
                    onClick={() => handleRemoveHero(hero.id)}
                    className={styles.clearIcon}
                  >
                    <IoCloseCircleOutline />
                  </div>
                )}
            </Avatar>
            <Caption>{hero.hero_name}</Caption>
          </div>
        ))}
    </>
  );
};

export default QuestHeroes;
