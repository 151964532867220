import React, { memo, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useNavigate, useSearchParams } from "react-router-dom";
import paths from "../../../pages/paths";
import { useSelector } from "react-redux";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import MiniCardImages from "./MiniCardImages";
import MiniCardTitle from "./MiniCardTitle";

const MiniCard = ({ character, delay, isQuest }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const navigate = useNavigate();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  const [searchParams] = useSearchParams();

  const queryParams = {
    page: String(searchParams.get("page") || 1),
    order_by: String(searchParams.get("order_by") || "total_stats_desc"),
    class_ids: String(searchParams.get("class_ids") || ""),
    hero_ids: String(searchParams.get("hero_ids") || ""),
    rarity_level_ids: String(searchParams.get("rarity_level_ids") || ""),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const handleHeroClick = () => {
    if (isQuest) return;
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(`${paths.hero}?${queryString}`, {
      state: { character: character },
    });
  };

  return (
    <div
      className={cx(styles.miniCardWrapper, {
        [styles.visible]: isVisible && allImagesLoaded,
      })}
      onClick={handleHeroClick}
    >
      <MiniCardImages
        character={character}
        onAllImagesLoaded={() => setAllImagesLoaded(true)}
      />
      {!isQuest && <MiniCardTitle character={character} />}
    </div>
  );
};

export default memo(MiniCard);
