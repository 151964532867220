import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import { getUserQuests } from "../../services/questsService";
import { useTelegram } from "../Layout/useTelegramHook";

export const useQuestsHistoryHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeQuestsPageStatus = location.state.activeStatus;
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  useTelegramBackButton(paths.quests, {
    state: { activeStatus: activeQuestsPageStatus },
  });

  const [isLoading, setIsLoading] = useState(true);
  const [quests, setQuests] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [questsCount, setQuestsCount] = useState(0);

  useEffect(() => {
    fetchUserData(dispatch);
  }, []);

  const observer = useRef();

  const fetchQuests = () => {
    getUserQuests(page, 10, "completed")
      .then((r) => {
        const newQuests = r.data.data;
        setQuests((prevQuests) => [...prevQuests, ...newQuests]);
        const paginationInfo = r.data.pagination_info || {};
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
        setQuestsCount(paginationInfo.total_entries);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchQuests();
  }, []);

  useEffect(() => {
    if (page !== 1) fetchQuests();
  }, [page]);

  const lastQuestElementRef = useCallback(
    (node) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  const navigateToQuest = (id) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(`${paths.quest.replace(":id", id)}`);
  };
  return {
    quests,
    navigateToQuest,
    lastQuestElementRef,
    isLoading,
    page,
    questsCount,
  };
};
