import { useTelegram } from "./useTelegramHook";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import usePreloadImages from "./usePreloadImages";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import paths from "../../pages/paths";

export const useDefaultLayoutHook = () => {
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  usePreloadImages(AsyncPreloadImages);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const isInitialEnter = useSelector((state) => state.telegram.initialEnter);
  const isDarkScheme = tg.colorScheme === "dark";

  useEffect(() => {
    if (tg.SettingsButton) {
      tg.SettingsButton.show();
      tg.SettingsButton.onClick(() => {
        if (hapticEnabled) tg.HapticFeedback.selectionChanged();
        navigate(paths.settings, { state: { from: pathname } });
      });
    }
  }, []);

  const pathsToCheck = [
    paths.referral,
    paths.settings,
    paths.task,
    paths.profile,
    paths.giveaway,
    paths.marketItem,
    paths.inventory,
    paths.transactions,
    paths.roulette,
    paths.heroDetail,
    paths.tasks,
    paths.quest,
  ];

  const isNoGameWrapper = pathsToCheck.some((path) =>
    matchPath({ path, end: true }, pathname),
  );

  const isNoGameInterface =
    pathname === paths.hero || pathname === paths.heroes;

  const isOnlyNavbar = pathname === paths.heroes;

  return {
    isInitialEnter,
    isDarkScheme,
    isNoGameWrapper,
    isNoGameInterface,
    isOnlyNavbar,
  };
};
