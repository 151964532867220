import { apiUrl } from "../utils/globals";
import { axiosInstance, axiosListInstance } from "./axiosService";

const getHeroes = (
  page,
  pageSize,
  orderParam = null,
  classIds = null,
  heroIds = null,
  rarityLevelIds = null,
  questId = undefined,
) => {
  const params = {
    page,
    page_size: pageSize,
    order_by: orderParam,
    ...(classIds ? { class_ids: classIds } : {}),
    ...(heroIds ? { hero_ids: heroIds } : {}),
    ...(rarityLevelIds ? { rarity_level_ids: rarityLevelIds } : {}),
    ...(questId !== undefined ? { active_quest_id: questId || "" } : {}),
  };

  return axiosListInstance.get(`${apiUrl}user_heroes`, { params });
};

const getHero = (id) => {
  return axiosInstance.get(`/user_heroes/${id}`);
};

const getDetailHeroes = (id, params = {}) => {
  const queryParams = {
    ...(params.page ? { page: params.page } : {}),
    ...(params.page_size ? { page_size: params.page_size } : {}),
    ...(params.order_by ? { order_by: params.order_by } : {}),
    ...(params.class_ids ? { class_ids: params.class_ids } : {}),
    ...(params.hero_ids ? { hero_ids: params.hero_ids } : {}),
    ...(params.rarity_level_ids
      ? { rarity_level_ids: params.rarity_level_ids }
      : {}),
  };

  return axiosInstance.get(`/user_heroes/${id}/previous_and_next`, {
    params: queryParams,
  });
};

export { getHeroes, getHero, getDetailHeroes };
