// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_heroDetailWrapper__7bKCN {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
}

.styles_avatar__EjJB0 {
  background: var(--tgui--bg_color);
}
.styles_avatar__EjJB0 > img {
  object-fit: contain;
}

.styles_cell__fcb4O {
  padding: unset;
}

.styles_cell__fcb4O:hover {
  background: transparent;
  opacity: 1;
}

.styles_levelInfo__a\\+AuC {
  color: var(--tg-theme-link-color);
}

.styles_white__nUU1c {
  color: white;
}

.styles_subtitle__AkOfL {
  display: flex;
  flex-direction: column;
}

@keyframes styles_fadeInAnimation__NrzgF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__reiYR {
  opacity: 0;
  animation: styles_fadeInAnimation__NrzgF 1s forwards;
}`, "",{"version":3,"sources":["webpack://./src/pages/HeroDetail/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,iCAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,uBAAA;EACA,UAAA;AAAF;;AAGA;EACE,iCAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE,UAAA;EACA,oDAAA;AADF","sourcesContent":[".heroDetailWrapper {\n  width: 100%;\n  height: 100%;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  overflow: auto;\n}\n\n.avatar {\n  background: var(--tgui--bg_color);\n\n  > img {\n    object-fit: contain;\n  }\n}\n\n.cell {\n  padding: unset;\n}\n\n.cell:hover {\n  background: transparent;\n  opacity: 1;\n}\n\n.levelInfo {\n  color: var(--tg-theme-link-color);\n}\n\n.white {\n  color: white;\n}\n\n.subtitle {\n  display: flex;\n  flex-direction: column;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroDetailWrapper": `styles_heroDetailWrapper__7bKCN`,
	"avatar": `styles_avatar__EjJB0`,
	"cell": `styles_cell__fcb4O`,
	"levelInfo": `styles_levelInfo__a+AuC`,
	"white": `styles_white__nUU1c`,
	"subtitle": `styles_subtitle__AkOfL`,
	"fadeIn": `styles_fadeIn__reiYR`,
	"fadeInAnimation": `styles_fadeInAnimation__NrzgF`
};
export default ___CSS_LOADER_EXPORT___;
