import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./utils/i18n";
import "@telegram-apps/telegram-ui/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { AppRoot } from "@telegram-apps/telegram-ui";
import { AppContainer } from "./AppContainer/AppContainer";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/discover\.pathgame\.app/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_RELEASE_LEVEL,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error?.isAxiosError && error.response?.status === 401) {
        return null;
      }
      return event;
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <AppRoot style={{ height: "100%" }}>
      <AppContainer />
    </AppRoot>
  </Provider>,
);
