// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalOverlay__UF-zV {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.styles_modalContent__eNu9i {
  max-width: 90%;
  width: 800px;
  height: 80%;
  position: relative;
  display: flex;
}

.styles_closeButton__Llxfs {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 100;
}

.styles_iframe__IzHyX {
  flex-grow: 1;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/InfoModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACF","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modalContent {\n  max-width: 90%;\n  width: 800px;\n  height: 80%;\n  position: relative;\n  display: flex;\n}\n\n.closeButton {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background: transparent;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  z-index: 100;\n}\n\n.iframe {\n  flex-grow: 1;\n  border: none;\n  width: 100%;\n  height: 100%;\n  border-radius: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `styles_modalOverlay__UF-zV`,
	"modalContent": `styles_modalContent__eNu9i`,
	"closeButton": `styles_closeButton__Llxfs`,
	"iframe": `styles_iframe__IzHyX`
};
export default ___CSS_LOADER_EXPORT___;
