// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.syles_progressContent__67vL\\+ {
  margin: 15px 70px 0 70px;
  padding: 6px 0;
  z-index: 2;
  -webkit-user-select: none;
          user-select: none;
}

.syles_progressWrapper__cxzCV {
  background: #2e3640;
  border-radius: 12px;
  width: 100%;
}

.syles_progress__wYD3E {
  width: 100%;
  z-index: 3;
}

.syles_detailProgress__wSxgS {
  margin: unset;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
}

.syles_popupContent__TNbIJ {
  padding: 16px;
  border-radius: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/HeroCardLevel/syles.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,cAAA;EACA,UAAA;EACA,yBAAA;UAAA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,UAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,oCAAA;EACA,wCAAA;AAAF","sourcesContent":[".progressContent {\n  margin: 15px 70px 0 70px;\n  padding: 6px 0;\n  z-index: 2;\n  user-select: none;\n}\n\n.progressWrapper {\n  background: #2e3640;\n  border-radius: 12px;\n  width: 100%;\n}\n\n.progress {\n  width: 100%;\n  z-index: 3;\n}\n\n.detailProgress {\n  margin: unset;\n  width: 100%;\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n\n\n.popupContent {\n  padding: 16px;\n  border-radius: 16px;\n  color: white;\n  background-color: rgba(0, 0, 0, 0.9);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressContent": `syles_progressContent__67vL+`,
	"progressWrapper": `syles_progressWrapper__cxzCV`,
	"progress": `syles_progress__wYD3E`,
	"detailProgress": `syles_detailProgress__wSxgS`,
	"popupContent": `syles_popupContent__TNbIJ`
};
export default ___CSS_LOADER_EXPORT___;
