import React from "react";
import styles from "./syles.module.scss";
import { Progress, Text } from "@telegram-apps/telegram-ui";
import Popup from "reactjs-popup";
import cx from "classnames";

const HeroCardLevel = ({ isDetail, character }) => {
  const transformDecimalToFraction = () => {
    const decimalPart = character.level % 1;
    return Math.round(decimalPart * 100);
  };

  if (isDetail) {
    return (
      <div className={cx(styles.progressContent, styles.detailProgress)}>
        <div className={styles.progressWrapper}>
          <Progress
            className={styles.progress}
            value={transformDecimalToFraction()}
          />
        </div>
        <Text>{`${character?.experience}/${character.next_level_experience}`}</Text>
      </div>
    );
  }
  return (
    <Popup
      keepTooltipInside
      trigger={
        <div className={styles.progressContent}>
          <div className={styles.progressWrapper}>
            <Progress
              className={styles.progress}
              value={transformDecimalToFraction()}
            />
          </div>
        </div>
      }
      position="bottom center"
    >
      <div className={styles.popupContent}>
        <Text>{`${character?.experience}/${character?.next_level_experience}`}</Text>
      </div>
    </Popup>
  );
};

export default HeroCardLevel;
