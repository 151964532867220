import React from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import HeroesForQuest from "../../components/ForHeroes/HeroesForQuest/HeroesForQuest";
import cx from "classnames";
import { useQuestHook } from "../../hooks/Pages/useQuestHook";
import QuestInfo from "./QuestInfo";
import QuestActions from "./QuestActions";
import QuestSnackbar from "./QuestSnackbar";

const Quest = () => {
  const {
    isLoading,
    isSelect,
    quest,
    endDate,
    attributeIcons,
    handleRemoveHero,
    selectedHeroes,
    setIsSelect,
    handleStartQuest,
    snackbarOpen,
    handleCloseSnackbar,
    handleOpenAnotherQuest,
    setSelectedHeroes,
    handleCloseHeroes,
    isChanceLoading,
    questChance,
  } = useQuestHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div
        className={cx(styles.wrapper, styles.fadeIn, {
          [styles.overflow]: !isSelect,
        })}
      >
        <QuestInfo
          quest={quest}
          attributeIcons={attributeIcons}
          endDate={endDate}
        />
        {!isSelect && (
          <QuestActions
            handleStartQuest={handleStartQuest}
            quest={quest}
            selectedHeroes={selectedHeroes}
            setIsSelect={setIsSelect}
            handleRemoveHero={handleRemoveHero}
            questChance={questChance}
            isChanceLoading={isChanceLoading}
          />
        )}
        {isSelect && (
          <HeroesForQuest
            handleCloseHeroes={handleCloseHeroes}
            selectedHeroes={selectedHeroes}
            setSelectedHeroes={setSelectedHeroes}
            heroesCount={quest.required_heroes_count}
            mainCharacteristic={quest.main_characteristic}
          />
        )}
      </div>
      {snackbarOpen && (
        <QuestSnackbar
          snackbarOpen={snackbarOpen}
          handleClick={handleOpenAnotherQuest}
          handleCloseSnackbar={handleCloseSnackbar}
        />
      )}
    </>
  );
};

export default Quest;
