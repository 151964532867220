import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getFilters } from "../../../../services/filtersService";

export const useQuestHeroesFiltersHook = (activeFilters) => {
  const { t } = useTranslation();

  const [classOptions, setClassOptions] = useState([]);
  const [heroOptions, setHeroOptions] = useState([]);
  const [rarityOptions, setRarityOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState(activeFilters);

  useEffect(() => {
    getFilters()
      .then((filtersData) => {
        setClassOptions(
          filtersData.classes.map((cls) => ({
            value: cls.id,
            label: cls.name,
          })),
        );
        setHeroOptions(
          filtersData.heroes.map((hero) => ({
            value: hero.id,
            label: hero.name,
          })),
        );
        setRarityOptions(
          filtersData.rarity_levels.map((rarity) => ({
            value: rarity.id,
            label: rarity.name,
          })),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value ? value.map((item) => item.value) : [],
    }));
  };

  const clearAllFilters = () => {
    setFilters({
      class_ids: [],
      hero_ids: [],
      rarity_level_ids: [],
    });
  };

  return {
    t,
    clearAllFilters,
    handleFilterChange,
    isLoading,
    filters,
    classOptions,
    rarityOptions,
    heroOptions,
  };
};
