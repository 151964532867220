// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_mainPageWrapper__p5QRb {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;
  color: white;
  padding: 16px;
  opacity: 0;
  animation: styles_fadeInAnimation__0QQhj 1s forwards;
}

.styles_activeFancy__lSmTU:active {
  transform: scale(0.95);
  transition: transform 0.3s;
}

.styles_buttonsColumn__pjUqx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 1;
}

.styles_fancyImagesRow__BSZTO {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.styles_coinImage__IDC6t {
  background: unset;
  box-shadow: unset;
}

.styles_fancyContent__ifCGD {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
  cursor: pointer;
}

.styles_fadeIn__JjPEj {
  opacity: 0;
  animation: styles_fadeInAnimation__0QQhj 1s forwards;
}

@keyframes styles_fadeInAnimation__0QQhj {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,oDAAA;AACF;;AAEA;EACE,sBAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,QAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,SAAA;EACA,eAAA;AACF;;AAEA;EACE,UAAA;EACA,oDAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".mainPageWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  height: 100%;\n  width: 100%;\n  color: white;\n  padding: 16px;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.activeFancy:active {\n  transform: scale(0.95);\n  transition: transform 0.3s;\n}\n\n.buttonsColumn {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  flex: 1;\n}\n\n.fancyImagesRow {\n  display: flex;\n  justify-content: space-between;\n  gap: 8px;\n}\n\n.coinImage {\n  background: unset;\n  box-shadow: unset;\n}\n\n.fancyContent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  gap: 10px;\n  cursor: pointer;\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainPageWrapper": `styles_mainPageWrapper__p5QRb`,
	"fadeInAnimation": `styles_fadeInAnimation__0QQhj`,
	"activeFancy": `styles_activeFancy__lSmTU`,
	"buttonsColumn": `styles_buttonsColumn__pjUqx`,
	"fancyImagesRow": `styles_fancyImagesRow__BSZTO`,
	"coinImage": `styles_coinImage__IDC6t`,
	"fancyContent": `styles_fancyContent__ifCGD`,
	"fadeIn": `styles_fadeIn__JjPEj`
};
export default ___CSS_LOADER_EXPORT___;
