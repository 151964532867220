import React from "react";
import {
  FixedLayout,
  Headline,
  Steps,
  Timeline,
} from "@telegram-apps/telegram-ui";
import styles from "./styles.module.scss";
import { TimelineItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/Timeline/components/TimelineItem/TimelineItem";
import { useGuideHook } from "../../../hooks/Registration/useGuideHook";
import { useTranslation } from "react-i18next";
import { RegistrationPreload } from "../../../utils/globals";
import { Icon24ChevronRight } from "@telegram-apps/telegram-ui/dist/icons/24/chevron_right";
import { Icon24ChevronLeft } from "@telegram-apps/telegram-ui/dist/icons/24/chevron_left";
import TelegramButton from "../../common/TelegramButton/TelegramButton";
import PageTitle from "../../PageTitle/PageTitle";

const Guide = () => {
  const { activeStep, handleNextStep, handlePreviousStep, handleStartGame } =
    useGuideHook();
  const { t } = useTranslation();

  return (
    <div className={`${styles.guideWrapper} ${styles.fadeIn}`}>
      <div className={styles.navigation}>
        <div className={styles.left} onClick={handlePreviousStep}>
          <Icon24ChevronLeft
            className={`${styles.chevronIcon} ${activeStep > 1 ? styles.chevronIconVisible : styles.chevronIconHidden}`}
          />
        </div>
        <div className={styles.right} onClick={handleNextStep}>
          <Icon24ChevronRight
            className={`${styles.chevronIcon} ${activeStep < 3 ? styles.chevronIconVisible : styles.chevronIconHidden}`}
          />
        </div>
      </div>
      <FixedLayout
        vertical="top"
        className={`${styles.fixedLayoutTop} ${styles.fadeIn}`}
      >
        <Steps count={3} progress={activeStep} />
        <div className={`${styles.guideContent} ${styles.fadeIn}`}>
          {activeStep === 1 && (
            <div className={`${styles.firstStep} ${styles.fadeIn}`}>
              <PageTitle isNoMargin title={t("get_rich_together")} />
              <img
                alt={t("silhouette")}
                className={`${styles.image} ${styles.fadeIn}`}
                src={RegistrationPreload.SILHOUETTE_1}
              />
            </div>
          )}
          {activeStep === 2 && (
            <div className={`${styles.secondStep} ${styles.fadeIn}`}>
              <PageTitle isNoMargin title={t("Roadmap")} />
              <Timeline active={5} className={styles.fadeIn}>
                <TimelineItem header={t("Tasks & Leaderboard")} />
                <TimelineItem header={t("Friends System")} />
                <TimelineItem header={t("Wallet Integration")} />
                <TimelineItem header={t("Giveaways")} />
                <TimelineItem header={t("Heroes & Quests")} />
                <TimelineItem header={t("Items")} />
                <TimelineItem header={t("Guilds")} />
                <TimelineItem header={t("PvP Battles & Dungeons")} />
              </Timeline>
            </div>
          )}
          {activeStep === 3 && (
            <div className={`${styles.thirdStep} ${styles.fadeIn}`}>
              <img
                alt={t("Welcome")}
                src={RegistrationPreload.COINS_BACKGROUND}
                className={`${styles.background} ${styles.fadeIn}`}
              />
              <PageTitle title={t("Welcome")} />
              <div className={`${styles.thirdStepContent} ${styles.fadeIn}`}>
                <div className={`${styles.box} ${styles.fadeIn}`}>
                  <Headline
                    className={`${styles.headline} ${styles.fadeIn}`}
                    weight="1"
                  >
                    <span>{t("starting_bonus")}</span>
                    <span>{t("premium_bonus")}</span>
                    <span>{t("referral_bonus")}</span>
                  </Headline>
                </div>
              </div>
            </div>
          )}
        </div>
      </FixedLayout>
      <FixedLayout className={`${styles.fixedLayoutBottom} ${styles.fadeIn}`}>
        {activeStep === 3 && (
          <TelegramButton stretched onClick={handleStartGame}>
            {t("start_to_earn")}
          </TelegramButton>
        )}
      </FixedLayout>
    </div>
  );
};

export default Guide;
