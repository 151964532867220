import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Button, Snackbar } from "@telegram-apps/telegram-ui";
import { updateReferralLink } from "../../services/settingsService";
import { fetchUserData } from "../../services/registrationService";
import { MdErrorOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

const ReferralLinkInput = ({ t }) => {
  const [referralLink, setReferralLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const dispatch = useDispatch();
  const referralCode = useSelector((state) => state.telegram.referralCode);

  useEffect(() => {
    fetchUserData(dispatch).then((data) => {
      setReferralLink(data.ref_code || "");
    });
  }, [dispatch]);

  const handleSetReferralLink = (e) => {
    setReferralLink(e.target.value);
    setIsButtonDisabled(e.target.value.length <= 3);
  };

  const handleSubmitReferralLink = () => {
    if (referralLink.length <= 3) {
      handleShowSnackbar();
      return;
    }

    setIsLoading(true);
    updateReferralLink(referralLink)
      .then(() => {
        setIsLoading(false);
        setIsButtonDisabled(true);
      })
      .catch(() => {
        setIsLoading(false);
        handleShowSnackbar();
      });
  };

  const handleShowSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className={styles.referralLinkWrapper}>
      <label htmlFor="referralLink" className={styles.label}>
        {t("Referral Link")}
      </label>
      <input
        className={styles.input}
        id="referralLink"
        type="text"
        required
        placeholder={t("Your Link")}
        value={referralLink}
        onChange={handleSetReferralLink}
        disabled={isLoading}
      />
      <p className={styles.description}>
        {t(
          "Update your referral link to a custom one. Your current link based on your Telegram ID will remain active, even after the change.",
        )}
      </p>
      <Button
        className={styles.updateButton}
        onClick={handleSubmitReferralLink}
        disabled={isButtonDisabled || isLoading}
      >
        {t("Update Referral Link")}
      </Button>

      {snackbarOpen && (
        <Snackbar
          className={styles.snackbar}
          before={<MdErrorOutline size={20} color="red" />}
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          description={t("Invalid referral link")}
        />
      )}
    </div>
  );
};

export default ReferralLinkInput;
