import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import { buyMarketItem, getMarketItem } from "../../services/marketService";
import { useTelegram } from "../Layout/useTelegramHook";
import { useSocket } from "../../utils/socketContext";

export const useMarketItemHook = () => {
  const location = useLocation();
  const isFromRoulette = location.state?.isFromRoulette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marketItemId = location.state?.id;
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  useTelegramBackButton(isFromRoulette ? paths.market : -1);
  const { t } = useTranslation();
  const { tg } = useTelegram();

  const [marketItem, setMarketItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, type: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const { userChannel } = useSocket();

  useEffect(() => {
    fetchUserData(dispatch);
    if (!marketItemId) {
      navigate(paths.market);
      return;
    }
    getMarketItem(marketItemId)
      .then(setMarketItem)
      .finally(() => {
        setIsLoading(false);
      });
  }, [marketItemId, navigate, dispatch]);

  const handleBuyStarMarketItem = () => {
    buyMarketItem(marketItem.id)
      .then((r) => {
        tg.openTelegramLink(r.payment_url);
      })
      .catch(() => showSnackbar("error"));
  };

  const handleBuyMarketItem = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (marketItem.price.currency === "STAR") {
      handleBuyStarMarketItem(marketItem.id);
      return;
    }
    buyMarketItem(marketItemId)
      .then((r) => {
        setActiveId(r.id);
        showSnackbar("success");
      })
      .catch(() => showSnackbar("error"));
  };

  const showSnackbar = (type, timeout = 3000) => {
    setSnackbar({ open: true, type });
    setTimeout(() => setSnackbar({ open: false, type: "" }), timeout);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsModalOpen(false);
  };

  const handleOpenRoulette = () => {
    navigate(paths.roulette, {
      state: { id: activeId },
    });
  };

  useEffect(() => {
    if (!userChannel) return;

    userChannel.onMessage = (event, payload) => {
      if (payload?.user_market_item_id) {
        setActiveId(payload?.user_market_item_id);
        setIsSuccessModalOpen(true);
      }
      return payload;
    };
  }, [userChannel]);

  return {
    handleCloseModal,
    handleOpenModal,
    handleBuyMarketItem,
    isLoading,
    isModalOpen,
    snackbar,
    t,
    marketItemId,
    setSnackbar,
    marketItem,
    activeId,
    handleOpenRoulette,
    isSuccessModalOpen,
    setIsSuccessModalOpen,
  };
};
