// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_fancyImage__nnMQ9 {
  height: 70px;
  width: 48%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.styles_button__EeIbH {
  cursor: pointer;
  border: unset;
  outline: unset;
  background-color: unset;
  padding: unset;
  position: relative;
  transition: transform 0.1s ease-in-out;
  background-size: contain;
  background-position: center;
  min-width: fit-content;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73%;
  height: 78px;
}

.styles_button__EeIbH:active {
  transform: scale(0.95);
  transition: transform 0.3s;
}

.styles_title__WCWZS {
  color: var(--tg-theme-text-color);
  white-space: nowrap;
}

.styles_badge__-RzSZ {
  position: absolute;
  top: 15px;
  right: -15px;
  transform: rotate(35deg);
  animation: styles_blink__PYOKN 2s infinite;
  transition: transform 0.3s;
}

@keyframes styles_blink__PYOKN {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ForMainPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,cAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,sCAAA;EACA,wBAAA;EACA,2BAAA;EACA,sBAAA;EACA,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;EACA,0BAAA;AACF;;AAEA;EACE,iCAAA;EACA,mBAAA;AACF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,wBAAA;EACA,0CAAA;EACA,0BAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,YAAA;EAAF;AACF","sourcesContent":[".fancyImage {\n  height: 70px;\n  width: 48%;\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.button {\n  cursor: pointer;\n  border: unset;\n  outline: unset;\n  background-color: unset;\n  padding: unset;\n  position: relative;\n  transition: transform 0.1s ease-in-out;\n  background-size: contain;\n  background-position: center;\n  min-width: fit-content;\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 73%;\n  height: 78px;\n}\n\n.button:active {\n  transform: scale(0.95);\n  transition: transform 0.3s;\n}\n\n.title {\n  color: var(--tg-theme-text-color);\n  white-space: nowrap;\n}\n\n\n.badge {\n  position: absolute;\n  top: 15px;\n  right: -15px;\n  transform: rotate(35deg);\n  animation: blink 2s infinite;\n  transition: transform 0.3s;\n}\n\n@keyframes blink {\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fancyImage": `styles_fancyImage__nnMQ9`,
	"button": `styles_button__EeIbH`,
	"title": `styles_title__WCWZS`,
	"badge": `styles_badge__-RzSZ`,
	"blink": `styles_blink__PYOKN`
};
export default ___CSS_LOADER_EXPORT___;
