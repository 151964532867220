// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__qiF3J {
  position: relative;
  text-align: center;
  color: var(--tg-theme-link-color);
  animation: styles_fadeIn__kXhcq 1s ease-in-out;
  font-family: Monaco, sans-serif;
  flex-shrink: 0;
  margin-bottom: 24px;
}

.styles_noMargin__Ho8TE {
  margin-bottom: unset;
}

.styles_titleContent__qrg8s {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_text__rzUYu {
  text-align: center;
}

.styles_leftComponent__RPhfi {
  position: absolute;
  left: 16px;
  display: flex;
  align-items: center;
}

.styles_rightComponent__Iu\\+mU {
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,iCAAA;EACA,8CAAA;EACA,+BAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AACF","sourcesContent":[".title {\n  position: relative;\n  text-align: center;\n  color: var(--tg-theme-link-color);\n  animation: fadeIn 1s ease-in-out;\n  font-family: Monaco, sans-serif;\n  flex-shrink: 0;\n  margin-bottom: 24px;\n}\n\n.noMargin {\n  margin-bottom: unset;\n}\n\n.titleContent {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.text {\n  text-align: center;\n}\n\n.leftComponent {\n  position: absolute;\n  left: 16px;\n  display: flex;\n  align-items: center;\n}\n\n.rightComponent {\n  position: absolute;\n  right: 16px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__qiF3J`,
	"fadeIn": `styles_fadeIn__kXhcq`,
	"noMargin": `styles_noMargin__Ho8TE`,
	"titleContent": `styles_titleContent__qrg8s`,
	"text": `styles_text__rzUYu`,
	"leftComponent": `styles_leftComponent__RPhfi`,
	"rightComponent": `styles_rightComponent__Iu+mU`
};
export default ___CSS_LOADER_EXPORT___;
