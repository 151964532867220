import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import paths from "../../pages/paths";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../Layout/useTelegramHook";
import { fetchUserData } from "../../services/registrationService";
import { getUserWallets } from "../../services/userService";
import useLoaderHook from "../Layout/useLoaderHook";
import { setInitialEnter } from "../../store/slices/telegramSlice";
import { getUserRating } from "../../services/ratingService";
import { PreloadImages } from "../../utils/globals";

export const useMainPageHook = () => {
  const { t } = useTranslation();
  const { tg } = useTelegram();
  useLoaderHook();

  const telegramData = useSelector((state) => state.telegram.telegramData);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const isDark = telegramData?.colorScheme === "dark";
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [userAmount, setUserAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userRating, setUserRating] = useState("");

  const fetchUserWallets = async () => {
    try {
      const response = (await getUserWallets()) || [];
      const wallet = response?.find(
        (wallet) => wallet?.balance?.currency === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }
    } catch (error) {
      console.error("Error fetching user wallets:", error);
    }
  };

  useEffect(() => {
    tg.BackButton.hide();
    const fetchData = async () => {
      try {
        await fetchUserData(dispatch);

        const ratingResponse = await getUserRating();
        setUserRating(ratingResponse.rank || "0");

        const walletsResponse = await fetchUserWallets();
        const wallet = walletsResponse?.find(
          (wallet) => wallet?.balance?.currency === "PATH",
        );
        if (wallet) {
          setUserAmount(wallet?.balance?.amount);
        }

        dispatch(setInitialEnter(false));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = (path) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(path, { state: { from: pathname } });
  };

  const buttonData = [
    {
      title: t("play"),
      backgroundImage: isDark
        ? PreloadImages.BLACK_BUTTON
        : PreloadImages.GRAY_BUTTON,
      onClick: () => {
        handleButtonClick(paths.quests);
      },
    },
    {
      title: t("tasks"),
      backgroundImage: isDark
        ? PreloadImages.BLACK_BUTTON
        : PreloadImages.GRAY_BUTTON,
      onClick: () => {
        handleButtonClick(paths.tasks);
      },
    },
    {
      title: t("Giveaways"),
      backgroundImage: isDark
        ? PreloadImages.BLACK_BUTTON
        : PreloadImages.GRAY_BUTTON,
      onClick: () => {
        handleButtonClick(paths.giveaways);
      },
    },
  ];

  const handleNavigateToRating = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.rating);
  };

  return {
    buttonData,
    userAmount,
    isLoading,
    userRating,
    handleNavigateToRating,
    handleButtonClick,
    t,
  };
};
