import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { getNextQuestTime, getUserQuests } from "../../services/questsService";
import { fetchUserData } from "../../services/registrationService";
import paths from "../../pages/paths";
import { useSocket } from "../../utils/socketContext";
import { useTelegram } from "../Layout/useTelegramHook";
import useAndroidScrollFix from "../Layout/useAndroidScrollFix";

export const useQuestsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const finishedQuestId = useSelector((state) => state.socket.finishedQuestId);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  useAndroidScrollFix();

  const statusFromQuery =
    searchParams.get("status") || location?.state?.activeStatus || "new";

  const [activeStatus, setActiveStatus] = useState(statusFromQuery);
  const [quests, setQuests] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [questsCount, setQuestsCount] = useState(0);
  const [nextQuestTime, setNextQuestTime] = useState(null);

  const observer = useRef();

  const fetchQuests = (reset = false) => {
    const questStatusMap = {
      new: "failed,created",
      active: "finished,started",
      default: "completed",
    };

    const questStatus = questStatusMap[activeStatus] || questStatusMap.default;

    getUserQuests(page, 10, questStatus)
      .then((r) => {
        const newQuests = r.data.data;
        setQuests((prevQuests) =>
          reset ? newQuests : [...prevQuests, ...newQuests],
        );
        const paginationInfo = r.data.pagination_info || {};
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
        setQuestsCount(paginationInfo.total_entries);
      })
      .finally(() => setIsDataLoading(false));
  };

  useEffect(() => {
    getNextQuestTime().then((r) => {
      const { current_quests_generation_at, next_quests_generation_at } = r;
      setNextQuestTime({
        startQuestTime: current_quests_generation_at,
        nextQuestTime: next_quests_generation_at,
      });
    });
    fetchUserData(dispatch).finally(() => setIsLoading(false));
  }, [dispatch]);

  const { lobbyChannel } = useSocket();

  useEffect(() => {
    if (!lobbyChannel) return;
    lobbyChannel.onMessage = (event, payload) => {
      if (event !== "quests_created") {
        return payload;
      }
      const { current_quests_generation_at, next_quests_generation_at } =
        payload;
      setNextQuestTime({
        startQuestTime: current_quests_generation_at,
        nextQuestTime: next_quests_generation_at,
      });
      if (activeStatus === "new") {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
        setIsDataLoading(true);
        setPage(1);
        fetchQuests(true);
      }
      return payload;
    };
  }, [lobbyChannel]);

  useEffect(() => {
    setIsDataLoading(true);
    setQuests([]);
    fetchQuests(true);
  }, [activeStatus]);

  useEffect(() => {
    if (page !== 1) fetchQuests();
  }, [page]);

  const lastQuestElementRef = useCallback(
    (node) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, quests],
  );

  const handleSetActiveStatus = (status) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setPage(1);
    setActiveStatus(status);
    setQuestsCount(null);
    setSearchParams({ status });
  };

  const navigateToQuest = (id) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(`${paths.quest.replace(":id", id)}`);
  };

  const handleGoToHistory = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.questsHistory, { state: { activeStatus: activeStatus } });
  };

  useEffect(() => {
    if (!finishedQuestId || activeStatus !== "active") return;
    setQuests((prevQuests) => {
      const questIndex = prevQuests.findIndex(
        (item) => item.id === finishedQuestId,
      );
      if (questIndex === -1) {
        return prevQuests;
      }
      const updatedQuests = [...prevQuests];
      updatedQuests[questIndex] = {
        ...updatedQuests[questIndex],
        status: "finished",
      };
      return updatedQuests;
    });
  }, [finishedQuestId]);

  return {
    quests,
    handleSetActiveStatus,
    navigateToQuest,
    lastQuestElementRef,
    isDataLoading,
    isLoading,
    page,
    activeStatus,
    questsCount,
    nextQuestTime,
    handleGoToHistory,
  };
};
