import React from "react";
import styles from "./styles.module.scss";
import { Text } from "@telegram-apps/telegram-ui";
import QuestHeroes from "./QuestHeroes";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import cx from "classnames";

const QuestActions = ({
  quest,
  selectedHeroes,
  handleRemoveHero,
  setIsSelect,
  handleStartQuest,
  questChance,
  isChanceLoading,
}) => {
  return (
    <>
      <div className={cx(styles.description, styles.fadeIn)}>
        <span className={styles.descriptionHeader}>Description</span>
        <Text>{quest.description}</Text>
      </div>
      {quest.status !== "completed" && (
        <QuestHeroes
          quest={quest}
          selectedHeroes={selectedHeroes}
          handleRemoveHero={handleRemoveHero}
          setIsSelect={setIsSelect}
        />
      )}
      {questChance &&
        selectedHeroes.length === quest.required_heroes_count &&
        quest.status !== "finished" &&
        quest.status !== "completed" && (
          <Text
            className={styles.fadeIn}
          >{`Шанс прохождения : ${questChance}%`}</Text>
        )}
      {quest.status !== "started" && quest.status !== "completed" && (
        <TelegramButton
          disabled={
            selectedHeroes.length < quest.required_heroes_count ||
            isChanceLoading
          }
          className={cx(styles.button, {
            [styles.disabledButton]: isChanceLoading,
          })}
          onClick={handleStartQuest}
        >
          {quest.status === "finished" ? "Finish" : `Start`}
        </TelegramButton>
      )}
    </>
  );
};

export default QuestActions;
