import React from "react";
import styles from "./styles.module.scss";
import { Headline } from "@telegram-apps/telegram-ui";

const MainPageButton = ({ button }) => {
  return (
    <button
      className={styles.button}
      onClick={button.onClick}
      style={{ backgroundImage: `url(${button.backgroundImage})` }}
    >
      <Headline className={styles.title} weight="1">
        {button.title}
      </Headline>
    </button>
  );
};

export default MainPageButton;
