import { useEffect, useState } from "react";
import { getHeroes } from "../../services/heroService";
import useLoaderHook from "../Layout/useLoaderHook";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../services/registrationService";
import { toast } from "react-toastify";
import styles from "../../pages/Heroes/styles.module.scss";
import miniCardStyles from "../../components/ForHeroes/MiniCard/styles.module.scss";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTelegram } from "../Layout/useTelegramHook";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";

export const useHeroesPageHook = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = parseInt(searchParams.get("page")) || 1;
  const orderParam = searchParams.get("order_by") || "total_stats_desc";
  const [isLoading, setIsLoading] = useState(true);
  const [heroes, setHeroes] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [initialRequest, setInitialRequest] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLoaderHook();
  const { tg } = useTelegram();
  const { t } = useTranslation();
  useTelegramBackButton(paths.index);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const classIdsParam = searchParams.get("class_ids") || "";
  const heroIdsParam = searchParams.get("hero_ids") || "";
  const rarityLevelIdsParam = searchParams.get("rarity_level_ids") || "";

  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    const filterCount = countAppliedFilters(
      classIdsParam,
      heroIdsParam,
      rarityLevelIdsParam,
    );
    setAppliedFiltersCount(filterCount);
  }, [classIdsParam, heroIdsParam, rarityLevelIdsParam]);

  const countAppliedFilters = (
    classIdsParam,
    heroIdsParam,
    rarityLevelIdsParam,
  ) => {
    let count = 0;

    if (classIdsParam) count++;
    if (heroIdsParam) count++;
    if (rarityLevelIdsParam) count++;

    return count;
  };

  const updatePageParam = (newPage, newOrderParam = orderParam) => {
    setSearchParams({
      page: newPage,
      order_by: newOrderParam,
      class_ids: classIdsParam,
      hero_ids: heroIdsParam,
      rarity_level_ids: rarityLevelIdsParam,
    });
  };

  const fetchHeroes = (page, reset = false) => {
    setIsLoading(true);
    getHeroes({
      page: page,
      pageSize: 6,
      orderParam: orderParam,
      classIds: classIdsParam,
      heroIds: heroIdsParam,
      rarityLevelIds: rarityLevelIdsParam,
    })
      .then((response) => {
        const heroes = response?.data?.data || [];
        const paginationInfo = response?.data?.pagination_info || {};
        setPaginationInfo(paginationInfo);
        setHeroes((prevHeroes) =>
          reset ? heroes : [...prevHeroes, ...heroes],
        );
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .catch(() => toast.error(t("Something went wrong, please reload page")))
      .finally(() => {
        setIsLoading(false);
        if (initialRequest) setInitialRequest(false);
      });
  };

  useEffect(() => {
    fetchUserData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    fetchHeroes(pageParam, true);
  }, [pageParam, orderParam, classIdsParam, heroIdsParam, rarityLevelIdsParam]);

  const goToNextPage = () => {
    if (isLoading || !hasMore) return;
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    updatePageParam(pageParam + 1);
  };

  const goToPrevPage = () => {
    if (isLoading || pageParam <= 1) return;
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    updatePageParam(pageParam - 1);
  };

  useEffect(() => {
    const heroesContainer = document.querySelector(`.${styles.heroes}`);

    if (!heroesContainer) {
      return;
    }

    const checkVisibility = () => {
      const anyVisible = Array.from(heroesContainer.children).some((child) =>
        child.classList.contains(miniCardStyles.visible),
      );
      setAllImagesLoaded(anyVisible);
    };

    checkVisibility();

    const observer = new MutationObserver(checkVisibility);
    observer.observe(heroesContainer, { attributes: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [heroes]);

  const handleOpenMarket = () => {
    navigate(paths.inventory);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (hasMore) {
        goToNextPage();
      } else {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      }
    },
    onSwipedRight: () => {
      if (pageParam > 1) {
        goToPrevPage();
      } else {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleOpenFiltersModal = () => {
    setIsFiltersModalOpen(true);
  };
  const handleOpenSortModal = () => {
    setIsSortModalOpen(true);
  };

  const areFiltersApplied =
    !!searchParams.get("class_ids") ||
    !!searchParams.get("hero_ids") ||
    !!searchParams.get("rarity_level_ids");

  return {
    isLoading,
    heroes,
    pageParam,
    hasMore,
    goToNextPage,
    goToPrevPage,
    allImagesLoaded,
    handleOpenMarket,
    initialRequest,
    paginationInfo,
    swipeHandlers,
    areFiltersApplied,
    appliedFiltersCount,
    orderParam,
    setSearchParams,
    searchParams,
    isSortModalOpen,
    handleOpenSortModal,
    isFiltersModalOpen,
    handleOpenFiltersModal,
    setIsSortModalOpen,
    setIsFiltersModalOpen,
  };
};
