// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_level__JS8Y\\+ {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.styles_topRow__7Iw05 {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 32px);
}

.styles_levelRow__qJjKY {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 10;
}

.styles_closeIcon__XFze7 {
  color: var(--tgui--plain_foreground);
}

.styles_header__WAcMn {
  padding: 16px 16px 0 16px;
}

.styles_classIcon__SaKlq {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
}

.styles_classContent__8QAcP {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 16px 16px;
  gap: 16px;
  width: 100%;
  text-align: center;
}

.styles_heroInfo__-K\\+0d {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px 16px 16px;
}

.styles_levelDigit__ykCDP {
  color: var(--tg-theme-link-color);
}

.styles_heroInfoRow__PW7Nj {
  display: flex;
  gap: 4px;
}

.styles_heroName__QFo8H {
  text-align: center;
}

.styles_heroImage__pWlq1 {
  object-fit: contain;
  height: 150px;
}

.styles_rarityRow__GXLu3 {
  display: flex;
  gap: 8px;
}

.styles_mainCharacteristic__4cGUE {
  text-transform: capitalize;
}

.styles_characteristicRow__shfOi {
  display: flex;
  align-items: center;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/HeroCardTopRow/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;AACF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,wBAAA;AAAF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;AADF;;AAIA;EACE,oCAAA;AADF;;AAIA;EACE,yBAAA;AADF;;AAKA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,yBAAA;AAFF;;AAKA;EACE,iCAAA;AAFF;;AAKA;EACE,aAAA;EACA,QAAA;AAFF;;AASA;EACE,kBAAA;AANF;;AASA;EACE,mBAAA;EACA,aAAA;AANF;;AASA;EACE,aAAA;EACA,QAAA;AANF;;AASA;EACE,0BAAA;AANF;;AASA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AANF","sourcesContent":[".level {\n  position: absolute;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: white;\n}\n\n\n.topRow {\n  display: flex;\n  justify-content: space-between;\n  position: absolute;\n  width: calc(100% - 32px);\n}\n\n\n.levelRow {\n  position: absolute;\n  left: 16px;\n  top: 16px;\n  z-index: 10;\n}\n\n.closeIcon {\n  color: var(--tgui--plain_foreground)\n}\n\n.header {\n  padding: 16px 16px 0 16px;\n}\n\n\n.classIcon {\n  position: absolute;\n  right: 16px;\n  top: 16px;\n  z-index: 10;\n}\n\n.classContent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 0 16px 16px 16px;\n  gap: 16px;\n  width: 100%;\n  text-align: center;\n}\n\n.heroInfo {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  padding: 0 16px 16px 16px;\n}\n\n.levelDigit {\n  color: var(--tg-theme-link-color);\n}\n\n.heroInfoRow {\n  display: flex;\n  gap: 4px;\n}\n\n.rarity {\n\n}\n\n.heroName {\n  text-align: center;\n}\n\n.heroImage {\n  object-fit: contain;\n  height: 150px;\n}\n\n.rarityRow {\n  display: flex;\n  gap: 8px;\n}\n\n.mainCharacteristic {\n  text-transform: capitalize;\n}\n\n.characteristicRow {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"level": `styles_level__JS8Y+`,
	"topRow": `styles_topRow__7Iw05`,
	"levelRow": `styles_levelRow__qJjKY`,
	"closeIcon": `styles_closeIcon__XFze7`,
	"header": `styles_header__WAcMn`,
	"classIcon": `styles_classIcon__SaKlq`,
	"classContent": `styles_classContent__8QAcP`,
	"heroInfo": `styles_heroInfo__-K+0d`,
	"levelDigit": `styles_levelDigit__ykCDP`,
	"heroInfoRow": `styles_heroInfoRow__PW7Nj`,
	"heroName": `styles_heroName__QFo8H`,
	"heroImage": `styles_heroImage__pWlq1`,
	"rarityRow": `styles_rarityRow__GXLu3`,
	"mainCharacteristic": `styles_mainCharacteristic__4cGUE`,
	"characteristicRow": `styles_characteristicRow__shfOi`
};
export default ___CSS_LOADER_EXPORT___;
