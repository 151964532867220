// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_loader__mCgVK {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0;
  animation: styles_fadeInAnimation__ZEloh 1s forwards;
}

@keyframes styles_fadeInAnimation__ZEloh {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/LoaderComponent/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,UAAA;EACA,oDAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `styles_loader__mCgVK`,
	"fadeInAnimation": `styles_fadeInAnimation__ZEloh`
};
export default ___CSS_LOADER_EXPORT___;
