import React from "react";
import styles from "./styles.module.scss";
import {
  Avatar,
  Cell,
  Snackbar,
  Text,
  Modal,
  Caption,
  Navigation,
} from "@telegram-apps/telegram-ui";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { useProfileHook } from "../../hooks/Pages/useProfileHook";
import { formatAmount } from "../../utils/formatNumbers";
import { FiCopy } from "react-icons/fi";
import { SiTon } from "react-icons/si";
import { PreloadImages } from "../../utils/globals";
import { Icon24Cancel } from "@telegram-apps/telegram-ui/dist/icons/24/cancel";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { AsyncPreloadImages } from "../../utils/asyncPreload";

const Profile = () => {
  const {
    isLoading,
    userAmount,
    userName,
    userRating,
    userWallet,
    truncateAddress,
    handleDeleteWallet,
    handleOpenConnectModal,
    handleCopyAddress,
    snackbarOpen,
    handleCloseSnackbar,
    handleOpenTransactions,
    handleOpenUserTasks,
    handleOpenTreasures,
    t,
    handleOpenSettings,
  } = useProfileHook();

  if (isLoading) return <LoaderComponent />;

  const renderCell = ({ title, subtitle, icon, action, onClick }) => (
    <Cell
      onClick={onClick}
      className={`${styles.cell} ${styles.fadeIn}`}
      before={<Avatar size={40} className={styles.avatar} src={icon} />}
      after={action}
      subtitle={subtitle}
    >
      <Text>{title}</Text>
    </Cell>
  );

  return (
    <>
      <div className={`${styles.wrapper} ${styles.fadeIn}`}>
        <Cell
          className={`${styles.topCell} ${styles.fadeIn}`}
          before={
            <Avatar
              className={styles.topAvatar}
              size={96}
              src={PreloadImages.PROFILE_LIGHT}
            />
          }
          subtitle={`${t("rank")} ${userRating || "-"}`}
        >
          <span className={styles.username}>{userName}</span>
        </Cell>
        <div className={styles.listWrapper}>
          <div className={`${styles.list} ${styles.fadeIn}`}>
            {renderCell({
              title: t("pathCoin"),
              subtitle: t("path"),
              icon: PreloadImages.COIN_ICON,
              action: (
                <Text className={styles.title}>{formatAmount(userAmount)}</Text>
              ),
            })}
            {renderCell({
              title: t("Inventory"),
              icon: AsyncPreloadImages.MY_TREASURES_ICON,
              action: <Navigation />,
              onClick: handleOpenTreasures,
            })}
            {renderCell({
              title: t("Transaction history"),
              icon: AsyncPreloadImages.TRANSACTIONS_HISTORY_ICON,
              action: <Navigation />,
              onClick: handleOpenTransactions,
            })}
            {renderCell({
              title: t("tasks"),
              icon: AsyncPreloadImages.TASKS_ICON,
              action: <Navigation />,
              onClick: handleOpenUserTasks,
            })}
            {renderCell({
              title: t("settings"),
              icon: AsyncPreloadImages.SETTINGS_ICON,
              action: <Navigation />,
              onClick: handleOpenSettings,
            })}

            <div className={`${styles.walletWrapper} ${styles.fadeIn}`}>
              {userWallet ? (
                <Cell
                  after={
                    <Modal
                      className={styles.modalWrapper}
                      trigger={
                        <div>
                          <Icon24Cancel className={styles.deleteIcon} />
                        </div>
                      }
                      header={
                        <ModalHeader
                          after={
                            <Icon28Close
                              style={{ color: "var(--tgui--plain_foreground)" }}
                            />
                          }
                        />
                      }
                    >
                      <div className={styles.modalContent}>
                        <Text>{t("unlink_wallet_confirmation")}</Text>
                        <TelegramButton onClick={handleDeleteWallet}>
                          {t("unlink")}
                        </TelegramButton>
                      </div>
                    </Modal>
                  }
                  className={`${styles.cell} ${styles.fadeIn}`}
                  before={
                    <Avatar
                      size={40}
                      className={styles.avatar}
                      src={PreloadImages.WALLET_ICON}
                    />
                  }
                >
                  <div className={styles.walletRow}>
                    <Text>{truncateAddress(userWallet)}</Text>
                    <FiCopy
                      onClick={handleCopyAddress}
                      className={styles.copy}
                      size={24}
                    />
                  </div>
                </Cell>
              ) : (
                <div className={styles.tonButtonWrapper}>
                  <TelegramButton onClick={handleOpenConnectModal}>
                    <div className={styles.tonButton}>
                      <SiTon />
                      <Caption>{t("connect_wallet")}</Caption>
                    </div>
                  </TelegramButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {snackbarOpen && (
        <Snackbar
          className={styles.snackbar}
          before={<FiCopy size={20} />}
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          description={t("Copied to clipboard")}
        />
      )}
    </>
  );
};

export default Profile;
