import React, { useState, useEffect } from "react";
import styles from "./QuestTimer.module.scss";
import { CircularProgress } from "@telegram-apps/telegram-ui";

const QuestTimer = ({ startQuestTime, nextQuestTime }) => {
  const [progress, setProgress] = useState(0);

  const calculateProgress = (startedAt, endedAt) => {
    const now = new Date();
    const start = new Date(startedAt);
    const end = new Date(endedAt);

    const totalDuration = end - start;

    if (totalDuration === 0) {
      return 0;
    }

    const remainingTime = end - now;
    return Math.max((remainingTime / totalDuration) * 100, 0);
  };

  useEffect(() => {
    const updateProgress = () => {
      setProgress(calculateProgress(startQuestTime, nextQuestTime));
    };

    updateProgress();
    const intervalId = setInterval(updateProgress, 1000);

    return () => clearInterval(intervalId);
  }, [startQuestTime, nextQuestTime]);

  return (
    <div className={styles.progress}>
      <CircularProgress progress={progress || 0} size="small" />
    </div>
  );
};

export default QuestTimer;
