import React from "react";
import styles from "./styles.module.scss";
import { useSettingsPageHook } from "../../hooks/Pages/useSettingsPageHook";
import LanguageSwitcher from "../../components/common/LanguageSwitcher/LanguageSwitcher";
import { FixedLayout, Switch, Text } from "@telegram-apps/telegram-ui";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import ReferralLinkInput from "./ReferralLinkInput"; // Import the referral link component

const Settings = () => {
  const {
    t,
    hapticEnabled,
    handleHapticToggle,
    activeLanguage,
    setActiveLanguage,
    isLoading,
    setIsLoading,
    volume,
    handleVolumeChange,
    navigateToMainPage,
  } = useSettingsPageHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={`${styles.settings} ${styles.fadeIn}`}>
      <div className={`${styles.field} ${styles.fadeIn}`}>
        <span className={`${styles.fieldTitle} ${styles.fadeIn}`}>
          {t("language")}
        </span>
        <LanguageSwitcher
          setIsLoading={setIsLoading}
          activeLanguage={activeLanguage}
          setActiveLanguage={setActiveLanguage}
        />
      </div>
      <div className={`${styles.field} ${styles.fadeIn}`}>
        <Text className={`${styles.text} ${styles.fadeIn}`}>
          {t("hapticFeedback")}
        </Text>
        <Switch checked={hapticEnabled} onChange={handleHapticToggle} />
      </div>
      <div className={`${styles.field} ${styles.fadeIn}`}>
        <Text className={`${styles.text} ${styles.fadeIn}`}>
          {t("Music level")}
        </Text>
        <input
          className={styles.range}
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
      </div>
      {/*<div className={`${styles.field} ${styles.fadeIn}`}>*/}
      {/*  <ReferralLinkInput t={t} />*/}
      {/*</div>*/}
      <FixedLayout className={styles.fixedLayout}>
        <TelegramButton onClick={navigateToMainPage}>
          {t("To the Main Page")}
        </TelegramButton>
      </FixedLayout>
    </div>
  );
};

export default Settings;
