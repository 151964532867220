import { useCallback, useEffect, useRef, useState } from "react";
import { getHeroes } from "../../../services/heroService";

const getAttributeValue = (attributeName) => {
  const attributeValues = {
    strength: "strength_desc",
    agility: "agility_desc",
    intelligence: "intelligence_desc",
    endurance: "endurance_desc",
    charisma: "charisma_desc",
    luck: "luck_desc",
  };

  return attributeValues[attributeName.toLowerCase()] || null;
};

export const useHeroesForQuestHook = ({
  selectedHeroes,
  setSelectedHeroes,
  handleCloseHeroes,
  heroesCount,
  mainCharacteristic,
}) => {
  const [heroes, setHeroes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [internalSelectedHeroes, setInternalSelectedHeroes] =
    useState(selectedHeroes);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    class_ids: [],
    hero_ids: [],
    rarity_level_ids: [],
  });
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  const [sortOption, setSortOption] = useState(
    getAttributeValue(mainCharacteristic),
  );

  const observer = useRef();

  const fetchHeroes = () => {
    if (!isDataLoading) setIsDataLoading(true);
    getHeroes(
      page,
      20,
      sortOption,
      filters.class_ids.join(","),
      filters.hero_ids.join(","),
      filters.rarity_level_ids.join(","),
      "",
    )
      .then((response) => {
        const newHeroes = response?.data?.data || [];
        setHeroes((prevHeroes) => [...prevHeroes, ...newHeroes]);
        const paginationInfo = response?.data?.pagination_info || {};
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => {
        setIsDataLoading(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setPage(1);
    setHeroes([]);
    fetchHeroes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortOption]);

  useEffect(() => {
    if (page !== 1) fetchHeroes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const lastHeroElementRef = useCallback(
    (node) => {
      if (isDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isDataLoading, hasMore],
  );

  useEffect(() => {
    let count = 0;
    Object.values(filters).forEach((filterArray) => {
      if (Array.isArray(filterArray) && filterArray.length > 0) {
        count += 1;
      }
    });
    setAppliedFiltersCount(count);
  }, [filters]);

  const handleCheckboxChange = (hero) => {
    setInternalSelectedHeroes((prevSelected) => {
      if (prevSelected.some((selectedHero) => selectedHero.id === hero.id)) {
        return prevSelected.filter(
          (selectedHero) => selectedHero.id !== hero.id,
        );
      }

      if (prevSelected.length < heroesCount) {
        return [...prevSelected, hero];
      }

      return prevSelected;
    });
  };

  const isHeroSelected = (heroId) =>
    internalSelectedHeroes.some((hero) => hero.id === heroId);

  const handleSelectHeroes = () => {
    setSelectedHeroes(internalSelectedHeroes);
    handleCloseHeroes();
  };

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const applySorting = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleOpenFiltersModal = () => {
    setIsFiltersModalOpen(true);
  };

  const handleOpenSortModal = () => {
    setIsSortModalOpen(true);
  };

  const getColorByClassname = (classname) => {
    const colors = {
      common: "saddlebrown",
      rare: "gray",
      elite: "orange",
      legendary: "cornflowerblue",
      divine: "rgb(50, 229, 94)",
    };

    return colors[classname] || "black";
  };

  const resetFilters = () => {
    setFilters({
      class_ids: [],
      hero_ids: [],
      rarity_level_ids: [],
    });
    setSortOption(getAttributeValue(mainCharacteristic));
    setPage(1);
    setHeroes([]);
  };

  return {
    heroes,
    isFiltersModalOpen,
    isSortModalOpen,
    getColorByClassname,
    handleOpenSortModal,
    handleOpenFiltersModal,
    applySorting,
    applyFilters,
    handleSelectHeroes,
    isHeroSelected,
    handleCheckboxChange,
    lastHeroElementRef,
    internalSelectedHeroes,
    isDataLoading,
    setIsFiltersModalOpen,
    setIsSortModalOpen,
    sortOption,
    isLoading,
    resetFilters,
    page,
    appliedFiltersCount,
    activeFilters: filters,
  };
};
