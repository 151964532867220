import React from "react";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import cx from "classnames";

const TelegramButton = ({
  onClick,
  className,
  disabled,
  before,
  children,
  ...props
}) => {
  const { tg } = useTelegram();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const handleClick = (event) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      className={cx(styles.button, className)}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {before && <div className={styles.icon}>{before}</div>}
      {children}
    </button>
  );
};

export default TelegramButton;
