// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_filtersContent__OH9du {
  padding: 16px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.styles_modalHeader__iOfUi {
  padding: 16px 16px 0 16px;
}

.styles_hidden__QACOR {
  visibility: hidden;
}

.styles_button__out9- {
  margin-top: auto;
}

.styles_noData__lEKVi {
  display: flex;
  flex: 1 1;
}

.styles_select__B98mQ {
  min-height: 42px;
}

.styles_clearAllButton__5TOJB {
  padding: 4px 0;
  outline: none;
  border: unset;
  background: none;
  color: var(--tg-theme-destructive-text-color);
  text-decoration: underline;
  cursor: pointer;
}

.styles_clearAllButton__5TOJB:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/FiltersModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,6CAAA;EACA,0BAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".filtersContent {\n  padding: 16px;\n  min-height: 80vh;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.modalHeader {\n  padding: 16px 16px 0 16px;\n}\n\n.hidden {\n  visibility: hidden;\n}\n\n.button {\n  margin-top: auto;\n}\n\n.noData {\n  display: flex;\n  flex: 1;\n}\n\n.select {\n  min-height: 42px;\n}\n\n.clearAllButton {\n  padding: 4px 0;\n  outline: none;\n  border: unset;\n  background: none;\n  color: var(--tg-theme-destructive-text-color);\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.clearAllButton:focus {\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersContent": `styles_filtersContent__OH9du`,
	"modalHeader": `styles_modalHeader__iOfUi`,
	"hidden": `styles_hidden__QACOR`,
	"button": `styles_button__out9-`,
	"noData": `styles_noData__lEKVi`,
	"select": `styles_select__B98mQ`,
	"clearAllButton": `styles_clearAllButton__5TOJB`
};
export default ___CSS_LOADER_EXPORT___;
