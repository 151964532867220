// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_languageSwitcher__I86sy {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.styles_languageSwitcher__I86sy > div {
  width: 100%;
}

.styles_switch__iOeBX {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.styles_switch__iOeBX input {
  opacity: 0;
  width: 0;
  height: 0;
}

.styles_slider__sf4j\\+ {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.styles_slider__sf4j\\+:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .styles_slider__sf4j\\+ {
  background-color: #2196F3;
}

input:checked + .styles_slider__sf4j\\+:before {
  transform: translateX(26px);
}

.styles_select__Kdzyf {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LanguageSwitcher/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,UAAA;EACA,QAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".languageSwitcher {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  > div {\n    width: 100%;\n  }\n}\n\n.switch {\n  position: relative;\n  display: inline-block;\n  width: 60px;\n  height: 34px;\n}\n\n.switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #ccc;\n  transition: .4s;\n  border-radius: 34px;\n}\n\n.slider:before {\n  position: absolute;\n  content: \"\";\n  height: 26px;\n  width: 26px;\n  left: 4px;\n  bottom: 4px;\n  background-color: white;\n  transition: .4s;\n  border-radius: 50%;\n}\n\ninput:checked + .slider {\n  background-color: #2196F3;\n}\n\ninput:checked + .slider:before {\n  transform: translateX(26px);\n}\n\n.select {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSwitcher": `styles_languageSwitcher__I86sy`,
	"switch": `styles_switch__iOeBX`,
	"slider": `styles_slider__sf4j+`,
	"select": `styles_select__Kdzyf`
};
export default ___CSS_LOADER_EXPORT___;
