import React, { memo } from "react";
import styles from "./styles.module.scss";
import TelegramButton from "../../common/TelegramButton/TelegramButton";
import LoaderComponent from "../../common/LoaderComponent/LoaderComponent";
import { useTranslation } from "react-i18next";
import SortModalForQuest from "./SortModalForQuest/SortModalForQuest";
import FiltersModalForQuest from "./FiltersModalForQuest/FiltersModalForQuest";
import { useHeroesForQuestHook } from "./useHeroesForQuestHook";
import HeroCell from "./HeroCell";
import { TbFilterOff } from "react-icons/tb";
import cx from "classnames";
import { Chip, FixedLayout } from "@telegram-apps/telegram-ui";
import { PiSortAscendingBold, PiSortDescendingBold } from "react-icons/pi";
import { TiDelete } from "react-icons/ti";

const HeroesForQuest = ({
  selectedHeroes,
  setSelectedHeroes,
  heroesCount,
  handleCloseHeroes,
  mainCharacteristic,
}) => {
  const {
    heroes,
    isFiltersModalOpen,
    isSortModalOpen,
    getColorByClassname,
    handleOpenSortModal,
    handleOpenFiltersModal,
    applySorting,
    applyFilters,
    handleSelectHeroes,
    isHeroSelected,
    handleCheckboxChange,
    lastHeroElementRef,
    internalSelectedHeroes,
    isDataLoading,
    setIsFiltersModalOpen,
    setIsSortModalOpen,
    sortOption,
    isLoading,
    resetFilters,
    appliedFiltersCount,
    page,
    activeFilters,
  } = useHeroesForQuestHook({
    selectedHeroes,
    setSelectedHeroes,
    handleCloseHeroes,
    heroesCount,
    mainCharacteristic,
  });

  const { t } = useTranslation();

  const getSortText = () => {
    switch (sortOption) {
      case "total_stats_desc":
        return { text: t("Strongest"), direction: "up" };
      case "total_stats_asc":
        return { text: t("Weakest"), direction: "down" };
      case "experience_asc":
        return { text: t("Least Experienced"), direction: "down" };
      case "experience_desc":
        return { text: t("Most Experienced"), direction: "up" };
      case "inserted_at_desc":
        return { text: t("Newest"), direction: "up" }; // No icon
      case "inserted_at_asc":
        return { text: t("Oldest"), direction: "down" }; // No icon
      case "strength_desc":
        return { text: t("Strength"), direction: "up" };
      case "agility_desc":
        return { text: t("Agility"), direction: "up" };
      case "intelligence_desc":
        return { text: t("Intelligence"), direction: "up" };
      case "endurance_desc":
        return { text: t("Endurance"), direction: "up" };
      case "charisma_desc":
        return { text: t("Charisma"), direction: "up" };
      case "luck_desc":
        return { text: t("Luck"), direction: "up" };
      default:
        return { text: t("Unknown parameter"), direction: null };
    }
  };

  const { text, direction } = getSortText();

  const renderArrowIcon = () => {
    if (direction === "up") return <PiSortAscendingBold size={17} />;
    if (direction === "down") return <PiSortDescendingBold size={17} />;
    return null;
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <div>
        {!!internalSelectedHeroes.length &&
          internalSelectedHeroes.map((hero) => {
            return (
              <Chip key={hero.id} className={cx(styles.chip, styles.fadeIn)}>
                <span>{hero.hero_name}</span>
                <TiDelete
                  size={20}
                  onClick={() => handleCheckboxChange(hero)}
                />
              </Chip>
            );
          })}
      </div>
      <div className={cx(styles.wrapper, styles.fadeIn)}>
        <div className={styles.topButtons}>
          <TelegramButton onClick={handleOpenFiltersModal}>
            {t("Filters")}
            {!!appliedFiltersCount && (
              <>
                &nbsp;
                <span className={styles.badge}>({appliedFiltersCount})</span>
              </>
            )}
          </TelegramButton>
          <TelegramButton onClick={resetFilters} className={styles.clearButton}>
            <div className={styles.clear}>
              <TbFilterOff size={17} />
            </div>
          </TelegramButton>
          <TelegramButton
            before={renderArrowIcon()}
            onClick={handleOpenSortModal}
          >
            {text}
          </TelegramButton>
        </div>
        <div className={styles.heroesList}>
          {isDataLoading && page === 1 && (
            <div className={styles.loading}>
              <LoaderComponent />
            </div>
          )}
          {heroes.map((hero, index) => {
            const isLastItem = heroes.length === index + 1;
            const refProp = isLastItem ? lastHeroElementRef : null;
            const isSelected = isHeroSelected(hero.id);
            const isDisabled =
              !isSelected && internalSelectedHeroes.length >= heroesCount;

            return (
              <HeroCell
                hero={hero}
                isSelected={isSelected}
                onCheckboxChange={handleCheckboxChange}
                isDisabled={isDisabled}
                getColorByClassname={getColorByClassname}
                heroesCount={heroesCount}
                internalSelectedHeroes={internalSelectedHeroes}
                ref={refProp}
                key={hero.id}
              />
            );
          })}
        </div>
        <FixedLayout>
          <div className={styles.buttonsRow}>
            <TelegramButton onClick={handleCloseHeroes}>
              {t("Отмена")}
            </TelegramButton>
            <TelegramButton
              className={cx({
                [styles.disabledButton]:
                  internalSelectedHeroes.length !== heroesCount,
              })}
              onClick={handleSelectHeroes}
              disabled={internalSelectedHeroes.length !== heroesCount}
            >
              {t("Выбрать")}
            </TelegramButton>
          </div>
        </FixedLayout>
        {isFiltersModalOpen && (
          <FiltersModalForQuest
            activeFilters={activeFilters}
            isModalOpen={isFiltersModalOpen}
            setIsModalOpen={setIsFiltersModalOpen}
            applyFilters={applyFilters}
          />
        )}
        {isSortModalOpen && (
          <SortModalForQuest
            isModalOpen={isSortModalOpen}
            setIsModalOpen={setIsSortModalOpen}
            applySorting={applySorting}
            selectedOption={sortOption}
          />
        )}
      </div>
    </>
  );
};

export default memo(HeroesForQuest);
