import React, { useEffect, useState } from "react";
import { Console, Hook, Unhook } from "console-feed";
import styles from "./styles.module.scss";

export const ConsoleViewer = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // Hook console methods
    Hook(
      window.console,
      (log) => setLogs((currLogs) => [...currLogs, log]),
      false,
    );

    // Cleanup on unmount
    return () => {
      Unhook(window.console);
    };
  }, []);

  // Render the console logs
  return (
    <div className={styles.consoleWrapper}>
      <Console logs={logs} variant="dark" />
    </div>
  );
};
