import React from "react";
import styles from "./syles.module.scss";
import { Progress, Text } from "@telegram-apps/telegram-ui";
import Popup from "reactjs-popup";
import cx from "classnames";

const HeroCardLevel = ({ isDetail, character }) => {
  const progressValue = (character.level - Math.floor(character.level)) * 100;

  if (isDetail) {
    return (
      <div className={cx(styles.progressContent, styles.detailProgress)}>
        <progress
          max={character.next_level_experience}
          className={styles.progress}
          value={character?.experience}
        />
        <Text>{`${character?.experience}/${character.next_level_experience}`}</Text>
      </div>
    );
  }
  return (
    <Popup
      keepTooltipInside
      trigger={
        <div className={styles.progressContent}>
          <progress
            className={styles.progress}
            value={character?.experience}
            max={character.next_level_experience}
          />
        </div>
      }
      position="bottom center"
    >
      <div className={styles.popupContent}>
        <Text>{`${character?.experience}/${character?.next_level_experience}`}</Text>
      </div>
    </Popup>
  );
};

export default HeroCardLevel;
