import React from "react";
import styles from "./styles.module.scss";
import { Caption, Cell, Chip, Image, Text } from "@telegram-apps/telegram-ui";
import { getColorByClassName } from "../../utils/imagesGetter";
import Countdown from "react-countdown";
import QuestAttributes from "./QuestAttributes";

const QuestInfo = ({ quest, endDate, attributeIcons }) => {
  return (
    <div className={styles.questInfo}>
      <Cell
        multiline
        className={styles.cell}
        before={
          <div className={styles.levelAvatar}>
            <Text className={styles.level}>{quest.level}</Text>
            <Image
              className={styles.rarityImg}
              size={48}
              src={`https://s3.pathgame.app/public/v2/quests/${quest.rarity_level}.webp`}
            />
          </div>
        }
        // after={<Chip>{quest.status}</Chip>}
        subtitle={
          <div className={styles.row}>
            <Caption className={styles.info}>
              <span>{`НАГРАДА: ${quest?.money_reward?.amount} ${quest?.money_reward?.currency}`}</span>
              <div>
                <span>Rarity:</span>&nbsp;
                <span
                  style={{ color: getColorByClassName(quest.rarity_level) }}
                >
                  {quest.rarity_level}
                </span>
              </div>
              <span>{`experience: ${quest.experience_reward}`}</span>
              <span>{`duration: ${quest.duration_in_seconds}`}</span>
            </Caption>
            <Chip>{quest.status}</Chip>
          </div>
        }
      >
        {quest.name}
      </Cell>
      <div className={styles.countDown}>
        {quest.status === "started" && <Countdown date={endDate} />}
      </div>
      <QuestAttributes attributeIcons={attributeIcons} quest={quest} />
    </div>
  );
};

export default QuestInfo;
