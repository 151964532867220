// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_imagesWrapper__WgZ5Z {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  transition: opacity 0.5s ease;
  margin-top: 30px;
  margin-bottom: 8px;
}

.styles_hidden__vtm50 {
  display: none;
}

.styles_visible__-mBqY {
  opacity: 1;
  transform: translateY(0);
}

.styles_cardWrapper__aULvU {
  z-index: 3;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

.styles_heroImg__vJWFX {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 45%;
  width: 100%;
  object-fit: contain;
  z-index: 1;
}

.styles_cardTexture__KBpOU {
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
}

.styles_background__Hsmja {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/HeroCard/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,6BAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,UAAA;EACA,wBAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,UAAA;AACF;;AAEA;EACE,6BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,MAAA;EACA,OAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".imagesWrapper {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  opacity: 0;\n  transition: opacity 0.5s ease;\n  margin-top: 30px;\n  margin-bottom: 8px;\n}\n\n.hidden {\n  display: none;\n}\n\n.visible {\n  opacity: 1;\n  transform: translateY(0);\n}\n\n.cardWrapper {\n  z-index: 3;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  position: absolute;\n}\n\n.heroImg {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  height: 45%;\n  width: 100%;\n  object-fit: contain;\n  z-index: 1;\n}\n\n.cardTexture {\n  position: absolute !important;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  top: 0;\n  left: 0;\n}\n\n.background {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imagesWrapper": `styles_imagesWrapper__WgZ5Z`,
	"hidden": `styles_hidden__vtm50`,
	"visible": `styles_visible__-mBqY`,
	"cardWrapper": `styles_cardWrapper__aULvU`,
	"heroImg": `styles_heroImg__vJWFX`,
	"cardTexture": `styles_cardTexture__KBpOU`,
	"background": `styles_background__Hsmja`
};
export default ___CSS_LOADER_EXPORT___;
