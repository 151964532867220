// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__xECbH {
  padding: 16px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.styles_badge__1voyE {
  position: absolute;
  top: 5px;
  right: -20px;
  transform: rotate(35deg);
  animation: styles_blink__TgrA\\+ 2s infinite;
  transition: transform 0.3s;
  z-index: 10;
}

.styles_marketItemsList__M6qB\\+ {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  width: 100%;
  margin: 0 auto;
}

.styles_coinImage__QfEhq {
  background: unset;
  box-shadow: unset;
}

.styles_topline__o7sMp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.styles_userBalance__MtUwX {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--tg-theme-link-color);
}

.styles_image__Tz-X- {
  cursor: pointer;
  box-shadow: unset;
  background: unset;
}

.styles_fadeIn__QraWe {
  opacity: 0;
  animation: styles_fadeInAnimation__TleAC 1s forwards;
}

@keyframes styles_fadeInAnimation__TleAC {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Market/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,YAAA;EACA,wBAAA;EACA,2CAAA;EACA,0BAAA;EACA,WAAA;AACF;;AAGA;EACE,aAAA;EACA,qCAAA;EACA,SAAA;EACA,WAAA;EACA,cAAA;AAAF;;AAGA;EACE,iBAAA;EACA,iBAAA;AAAF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,iCAAA;AADF;;AAKA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,UAAA;EACA,oDAAA;AAFF;;AAKA;EACE;IACE,UAAA;EAFF;EAIA;IACE,UAAA;EAFF;AACF","sourcesContent":[".container {\n  padding: 16px;\n  border-radius: 8px;\n  height: 100%;\n  width: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.badge {\n  position: absolute;\n  top: 5px;\n  right: -20px;\n  transform: rotate(35deg);\n  animation: blink 2s infinite;\n  transition: transform 0.3s;\n  z-index: 10;\n}\n\n\n.marketItemsList {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 12px;\n  width: 100%;\n  margin: 0 auto;\n}\n\n.coinImage {\n  background: unset;\n  box-shadow: unset;\n}\n\n\n.topline {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 16px;\n}\n\n.userBalance {\n  width: 100%;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  color: var(--tg-theme-link-color);\n}\n\n\n.image {\n  cursor: pointer;\n  box-shadow: unset;\n  background: unset;\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__xECbH`,
	"badge": `styles_badge__1voyE`,
	"blink": `styles_blink__TgrA+`,
	"marketItemsList": `styles_marketItemsList__M6qB+`,
	"coinImage": `styles_coinImage__QfEhq`,
	"topline": `styles_topline__o7sMp`,
	"userBalance": `styles_userBalance__MtUwX`,
	"image": `styles_image__Tz-X-`,
	"fadeIn": `styles_fadeIn__QraWe`,
	"fadeInAnimation": `styles_fadeInAnimation__TleAC`
};
export default ___CSS_LOADER_EXPORT___;
