// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root, html {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

html, body {
    background-color: var(--tg-theme-bg-color);
    padding-bottom: env(safe-area-inset-bottom);
}

body {
    overflow: hidden;
    display: flex;
    flex: 1 1;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-user-select: none;
    user-select: none;
    outline: none;
}

a:hover {
    text-decoration: underline;
}

* {
    box-sizing: border-box;
    z-index: 1;
    font-family: 'Monaco', 'Menlo', 'Courier', monospace !important;
}

h6 {
    padding: unset;
    line-height: unset !important;
}

html {
    scroll-behavior: unset !important;
}

body {
    touch-action: none !important;
}

iframe
{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAO;AACX;;AAEA;IACI,0CAA0C;IAC1C,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAO;IACP,SAAS;IACT;;cAEU;IACV,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI;aACS;AACb;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,yBAAyB;IAGzB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;IACtB,UAAU;IACV,+DAA+D;AACnE;;AAEA;IACI,cAAc;IACd,6BAA6B;AACjC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":["#root, html {\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n}\n\nhtml, body {\n    background-color: var(--tg-theme-bg-color);\n    padding-bottom: env(safe-area-inset-bottom);\n}\n\nbody {\n    overflow: hidden;\n    display: flex;\n    flex: 1;\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\na {\n    text-decoration: none;\n    color: inherit;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    outline: none;\n}\n\na:hover {\n    text-decoration: underline;\n}\n\n* {\n    box-sizing: border-box;\n    z-index: 1;\n    font-family: 'Monaco', 'Menlo', 'Courier', monospace !important;\n}\n\nh6 {\n    padding: unset;\n    line-height: unset !important;\n}\n\nhtml {\n    scroll-behavior: unset !important;\n}\n\nbody {\n    touch-action: none !important;\n}\n\niframe\n{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
