import React from "react";
import { Cell, Modal, Radio, Section } from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import styles from "./styles.module.scss";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { useSelector } from "react-redux";
import {
  getAttributeOptions,
  getSortOptions,
} from "../../../utils/imagesGetter";

const SortModal = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOption = searchParams.get("order_by") || "total_stats_desc";

  const handleCheckboxChange = (value) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    searchParams.set("order_by", value);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setIsModalOpen(false);
  };

  const sortOptions = getSortOptions(t);
  const attributeOptions = getAttributeOptions(t);

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={(open) => {
        if (!open) setIsModalOpen(false);
      }}
      header={
        <ModalHeader
          className={styles.modalHeader}
          after={
            <ModalClose>
              <Icon28Close
                style={{ color: "var(--tgui--plain_foreground)" }}
                onClick={() => setIsModalOpen(false)}
              />
            </ModalClose>
          }
        />
      }
    >
      <Section header={t("Sort by Overall Stats")}>
        {sortOptions.map((option, index) => (
          <Cell
            className={styles.cell}
            key={index}
            Component="label"
            before={
              <Radio
                name="checkbox"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleCheckboxChange(option.value)}
              />
            }
            multiline
          >
            {option.label}
          </Cell>
        ))}
      </Section>
      <Section header={t("By Attribute in Descending Order")}>
        {attributeOptions.map((option, index) => (
          <Cell
            className={styles.cell}
            key={index}
            Component="label"
            before={
              <Radio
                name="checkbox"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleCheckboxChange(option.value)}
              />
            }
            multiline
          >
            {option.label}
          </Cell>
        ))}
      </Section>
    </Modal>
  );
};

export default SortModal;
