// SortModalForQuest.jsx
import React from "react";
import { Cell, Modal, Radio, Section } from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import styles from "../../SortModal/styles.module.scss";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { useTranslation } from "react-i18next";

const SortModalForQuest = ({
  isModalOpen,
  setIsModalOpen,
  applySorting,
  selectedOption,
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = (value) => {
    applySorting(value);
    setIsModalOpen(false);
  };

  const sortOptions = [
    { label: t("Strongest First"), value: "total_stats_desc" },
    { label: t("Weakest First"), value: "total_stats_asc" },
    { label: t("Highest Level"), value: "experience_desc" },
    { label: t("Lowest Level"), value: "experience_asc" },
    { label: t("Newest First"), value: "inserted_at_desc" },
    { label: t("Oldest First"), value: "inserted_at_asc" },
  ];

  const attributeOptions = [
    { label: t("Strength"), value: "strength_desc" },
    { label: t("Agility"), value: "agility_desc" },
    { label: t("Intelligence"), value: "intelligence_desc" },
    { label: t("Endurance"), value: "endurance_desc" },
    { label: t("Charisma"), value: "charisma_desc" },
    { label: t("Luck"), value: "luck_desc" },
  ];

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={(open) => {
        if (!open) setIsModalOpen(false);
      }}
      header={
        <ModalHeader
          className={styles.modalHeader}
          after={
            <ModalClose>
              <Icon28Close
                style={{ color: "var(--tgui--plain_foreground)" }}
                onClick={() => setIsModalOpen(false)}
              />
            </ModalClose>
          }
        />
      }
    >
      <Section header={t("Sort by Overall Stats")}>
        {sortOptions.map((option, index) => (
          <Cell
            className={styles.cell}
            key={index}
            Component="label"
            before={
              <Radio
                name="sortOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleCheckboxChange(option.value)}
              />
            }
            multiline
          >
            {option.label}
          </Cell>
        ))}
      </Section>
      <Section header={t("By Attribute in Descending Order")}>
        {attributeOptions.map((option, index) => (
          <Cell
            className={styles.cell}
            key={index}
            Component="label"
            before={
              <Radio
                name="sortOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleCheckboxChange(option.value)}
              />
            }
            multiline
          >
            {option.label}
          </Cell>
        ))}
      </Section>
    </Modal>
  );
};

export default SortModalForQuest;
