import React from "react";
import styles from "./styles.module.scss";
import { Avatar, Cell, Text } from "@telegram-apps/telegram-ui";
import AttributesRow from "../../components/ForHeroes/AttributesRow/AttributesRow";
import HeroCardLevel from "../../components/ForHeroes/HeroCardLevel/HeroCardLevel";
import { useHeroDetailHook } from "../../hooks/Pages/useHeroDetailHook";
import HeroSkills from "../../components/ForHeroes/HeroSkills/HeroSkills";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";

const HeroDetail = () => {
  const { t } = useTranslation();

  const {
    activeSkill,
    handlePrevSkill,
    handleNextSkill,
    handleSkillClick,
    displayedSkills,
    getColorByClassname,
    isDarkScheme,
    heroData,
    isLoading,
  } = useHeroDetailHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.heroDetailWrapper, styles.fadeIn)}>
      <Cell
        className={styles.cell}
        after={
          <img
            className={styles.classIcon}
            alt={"classIcon"}
            src={heroData.class_image_url}
            width={"50px"}
            height={"50px"}
          />
        }
        before={
          <Avatar
            className={styles.avatar}
            src={heroData.hero_preview_url}
            size={96}
          />
        }
        description={
          <div>
            <span className={styles.white}>{`${t("Level")} `}</span>
            <span className={styles.levelInfo}>
              {Math.floor(heroData.level)}
            </span>
          </div>
        }
        subtitle={
          <div className={styles.subtitle}>
            <span className={styles.white}>{heroData.class}</span>
            <span
              style={{
                color: getColorByClassname(heroData.rarity_level.toLowerCase()),
              }}
            >
              {heroData.rarity_level_name}
            </span>
          </div>
        }
      >
        {heroData.hero_name}
      </Cell>
      <HeroCardLevel character={heroData} isDetail />
      <AttributesRow character={heroData} isDetail />
      <Text>{heroData.hero_bio}</Text>
      <HeroSkills
        isDarkScheme={isDarkScheme}
        activeSkill={activeSkill}
        displayedSkills={displayedSkills}
        handleNextPage={handleNextSkill}
        handlePrevPage={handlePrevSkill}
        handleSkillClick={handleSkillClick}
        getColorByClassname={getColorByClassname}
      />
    </div>
  );
};

export default HeroDetail;
