import React from "react";
import { Snackbar } from "@telegram-apps/telegram-ui";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { useTranslation } from "react-i18next";
import { PiSealQuestionLight } from "react-icons/pi";

const QuestSnackbar = ({ snackbarOpen, handleClick, handleCloseSnackbar }) => {
  const { t } = useTranslation();
  return (
    <Snackbar
      before={<PiSealQuestionLight size={30} />}
      open={snackbarOpen}
      onClose={handleCloseSnackbar}
      description={"Нажмите кнопку, чтобы проверить"}
      after={<TelegramButton onClick={handleClick}>{t("Open")}</TelegramButton>}
    >
      {"Вы завершили квест!"}
    </Snackbar>
  );
};

export default QuestSnackbar;
