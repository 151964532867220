import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import {
  Avatar,
  Cell,
  List,
  Navigation,
  SegmentedControl,
  Text,
} from "@telegram-apps/telegram-ui";
import cx from "classnames";
import { useQuestsHook } from "../../hooks/Pages/useQuestsHook";
import QuestSnackbar from "../Quest/QuestSnackbar";
import { AsyncPreloadImages } from "../../utils/asyncPreload";

const Quests = () => {
  const {
    quests,
    handleSetActiveStatus,
    navigateToQuest,
    attributeIcons,
    lastQuestElementRef,
    isDataLoading,
    isLoading,
    page,
    activeStatus,
    getColorByStatus,
    handleCloseSnackbar,
    snackbarOpen,
    handleOpenCompletedQuest,
  } = useQuestsHook();

  if (isLoading && page === 1) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={cx(styles.wrapper, styles.fadeIn)}>
        <PageTitle title={"Quests"} />
        <div className={styles.segmentControlWrapper}>
          <SegmentedControl
            className={`${styles.segmentControl} ${styles.fadeIn}`}
          >
            <SegmentedControlItem
              className={styles.controlItem}
              selected={activeStatus === "new"}
              onClick={() => handleSetActiveStatus("new")}
            >
              {"New"}
            </SegmentedControlItem>
            <SegmentedControlItem
              className={styles.controlItem}
              selected={activeStatus === "active"}
              onClick={() => handleSetActiveStatus("active")}
            >
              {"Active"}
            </SegmentedControlItem>
            <SegmentedControlItem
              className={styles.controlItem}
              selected={activeStatus === "completed"}
              onClick={() => handleSetActiveStatus("completed")}
            >
              {"Finished"}
            </SegmentedControlItem>
          </SegmentedControl>
        </div>
        {isDataLoading && page === 1 && (
          <div className={styles.loader}>
            <LoaderComponent />
          </div>
        )}
        {!isDataLoading && !quests.length && (
          <div className={styles.noDataWrapper}>
            <img
              className={styles.noDataImg}
              alt={"no quest"}
              src={AsyncPreloadImages.NO_QUESTS}
            />
          </div>
        )}
        {
          <List className={cx(styles.list, styles.fadeIn)}>
            {quests.map((quest, index) => (
              <Cell
                multiline
                key={quest.id}
                titleBadge={
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      background: getColorByStatus(quest.status),
                      minWidth: 10,
                      minHeight: 10,
                      borderRadius: "50%",
                    }}
                  />
                }
                onClick={() => navigateToQuest(quest.id)}
                className={styles.cell}
                after={<Navigation />}
                before={
                  <div className={styles.levelAvatar}>
                    <Text className={styles.level}>{quest.level}</Text>
                    <Avatar
                      className={styles.rarityImg}
                      size={48}
                      src={`https://s3.pathgame.app/public/v2/quests/${quest.rarity_level}.webp`}
                    />
                  </div>
                }
                subtitle={
                  <div className={styles.attributeIcons}>
                    {Object.entries({
                      strength: quest.strength,
                      agility: quest.agility,
                      intelligence: quest.intelligence,
                      endurance: quest.endurance,
                      charisma: quest.charisma,
                    })
                      .filter(([_, value]) => value > 0)
                      .map(([key]) => (
                        <img
                          height={"20px"}
                          width={"20px"}
                          key={key}
                          src={attributeIcons[key]}
                          alt={key}
                          className={styles.icon}
                        />
                      ))}
                  </div>
                }
                description={`НАГРАДА: ${quest?.money_reward.amount} ${quest?.money_reward.currency}`}
                ref={index === quests.length - 1 ? lastQuestElementRef : null}
              >
                {quest.name}
              </Cell>
            ))}
          </List>
        }
      </div>
      {snackbarOpen && (
        <QuestSnackbar
          snackbarOpen={snackbarOpen}
          handleClick={handleOpenCompletedQuest}
          handleCloseSnackbar={handleCloseSnackbar}
        />
      )}
    </>
  );
};

export default Quests;
