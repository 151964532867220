import React from "react";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { TbFilterOff } from "react-icons/tb";
import { PiSortAscendingBold, PiSortDescendingBold } from "react-icons/pi";
import { getSortText } from "../../utils/imagesGetter";

const FiltersButtonsRow = ({
  onSortClick,
  onFiltersClick,
  appliedFiltersCount,
  orderParam,
  searchParams,
  setSearchParams,
}) => {
  const { t } = useTranslation();
  const { text, direction } = getSortText(orderParam, t);

  const renderArrowIcon = () => {
    if (direction === "up") return <PiSortAscendingBold size={17} />;
    if (direction === "down") return <PiSortDescendingBold size={17} />;
    return null;
  };

  const clearAllFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove all filter-related parameters
    newSearchParams.delete("class_ids");
    newSearchParams.delete("hero_ids");
    newSearchParams.delete("rarity_level_ids");

    // Reset the sorting to the default
    newSearchParams.set("order_by", "total_stats_desc");

    // Ensure the page resets to 1 when clearing filters
    newSearchParams.set("page", 1);

    setSearchParams(newSearchParams); // Update URL params
  };

  return (
    <div className={cx(styles.filtersButtonsRow, styles.fadeIn)}>
      <TelegramButton onClick={onFiltersClick} className={styles.filtersButton}>
        {t("Filters")}&nbsp;
        {!!appliedFiltersCount && (
          <span className={styles.badge}>({appliedFiltersCount})</span>
        )}
      </TelegramButton>
      {(appliedFiltersCount > 0 || orderParam !== "total_stats_desc") && (
        <TelegramButton
          onClick={clearAllFilters}
          className={styles.clearButton}
        >
          <div className={styles.clear}>
            <TbFilterOff size={17} />
          </div>
        </TelegramButton>
      )}
      <TelegramButton
        before={renderArrowIcon()}
        onClick={onSortClick}
        className={styles.filtersButton}
      >
        {text}
      </TelegramButton>
    </div>
  );
};

export default FiltersButtonsRow;
