import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { useTelegram } from "../Layout/useTelegramHook";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setGlobalLoading } from "../../store/slices/loaderSlice";
import { fetchUserData } from "../../services/registrationService";
import { updateUserWallet } from "../../services/walletService";
import { toast } from "react-toastify";
import { getUserWallets } from "../../services/userService";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserRating } from "../../services/ratingService";
import { Buffer } from "buffer";
import * as TON_CONNECT_UI from "@tonconnect/ui";
import { useTranslation } from "react-i18next";
import paths from "../../pages/paths";

window.Buffer = Buffer;
const TonWeb = require("tonweb");

export const useProfileHook = () => {
  const location = useLocation();
  const taskId = location.state?.id;
  const backState = taskId
    ? { state: { from: location.pathname, id: taskId } }
    : {};

  useTelegramBackButton(-1, backState, !!taskId);
  const { tg } = useTelegram();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const telegramData = useSelector((state) => state.telegram.telegramData);
  const colorScheme = telegramData?.colorScheme;
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [isLoading, setIsLoading] = useState(true);
  const [userAmount, setUserAmount] = useState(null);
  const [userName, setUsername] = useState("");
  const [userRating, setUserRating] = useState("");
  const [userWallet, setUserWallet] = useState(null);
  const [tonConnectUI, setTonConnectUI] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (!window.tonConnectUI) {
      const tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl:
          "https://s3.pathgame.app/public/config/tonconnect-manifest.json",
      });
      window.tonConnectUI = tonConnectUI;
      setTonConnectUI(tonConnectUI);
    } else {
      setTonConnectUI(window.tonConnectUI);
    }
  }, []);

  const fetchUserWallets = async () => {
    try {
      const response = await getUserWallets();
      const wallet = response?.find(
        (wallet) => wallet?.balance?.currency === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }
    } catch (error) {
      console.error("Error fetching user wallets:", error);
    }
  };

  const truncateAddress = (address) => {
    if (!address) return "";
    const firstPart = address.substring(0, 6);
    const lastPart = address.substring(address.length - 6);
    return `${firstPart}...${lastPart}`;
  };

  const fetchData = async () => {
    try {
      const userRes = await fetchUserData(dispatch);
      if (userRes) {
        setUsername(userRes?.username);
      }

      const walletAddressFromBackend =
        userRes?.user_settings?.ton_wallet_address;
      const currentWallet = tonConnectUI?.wallet;

      setUserWallet(walletAddressFromBackend);

      if (!walletAddressFromBackend && currentWallet) {
        await tonConnectUI.disconnect();
        console.log(t("Wallet disconnected due to null from backend"));
      }

      const [walletsResponse, ratingResponse] = await Promise.all([
        fetchUserWallets(),
        getUserRating(),
      ]);
      const wallet = walletsResponse?.find(
        (wallet) => wallet?.balance?.currency === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }

      setUserRating(ratingResponse.rank);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const convertToUserFriendlyAddress = (rawAddress) => {
    const address = new TonWeb.utils.Address(rawAddress);
    return address.toString(true, true, false, false);
  };

  useEffect(() => {
    const handleStatusChange = (wallet) => {
      if (wallet && !userWallet) {
        updateUserWallet(
          convertToUserFriendlyAddress(wallet?.account?.address),
        ).then(() => fetchData());
      }
    };

    tonConnectUI?.onStatusChange(handleStatusChange);
    return () => {
      tonConnectUI?.closeModal();
    };
  }, [tonConnectUI, userWallet]);

  const handleDeleteWallet = async () => {
    try {
      const wallet = tonConnectUI?.wallet;

      if (wallet) {
        await tonConnectUI.disconnect();
        console.log(t("Wallet disconnected"));
      } else {
        console.log(t("No wallet connected"));
      }

      await updateUserWallet(null);
      await fetchData();
    } catch (error) {
      toast.error(t("Failed to delete wallet. Please try again."), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleOpenConnectModal = () => {
    const currentWallet = tonConnectUI?.wallet;
    if (currentWallet) {
      tonConnectUI.disconnect().then(() => tonConnectUI.openModal());
      return;
    }
    tonConnectUI.openModal();
  };

  const handleCopyAddress = () => {
    const textArea = document.createElement("textarea");
    textArea.value = userWallet;

    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = "0";
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? t("successful") : t("unsuccessful");
      console.log("Copying text command was " + msg);
      tg.HapticFeedback.notificationOccurred("success");
      handleShowSnackbar();
    } catch (err) {
      console.error("Failed to copy: ", err);
    }

    document.body.removeChild(textArea);
  };

  const handleShowSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenTransactions = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.transactions);
  };
  const handleOpenTreasures = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.inventory, { state: { from: location.pathname } });
  };
  const handleOpenUserTasks = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.tasks, { state: { from: location.pathname } });
  };

  return {
    isLoading,
    userAmount,
    userName,
    userRating,
    colorScheme,
    userWallet,
    truncateAddress,
    handleDeleteWallet,
    handleOpenConnectModal,
    handleCopyAddress,
    snackbarOpen,
    handleCloseSnackbar,
    t,
    handleOpenTransactions,
    handleOpenTreasures,
    handleOpenUserTasks,
  };
};
