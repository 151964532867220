import { useLocation, useNavigate } from "react-router-dom";
import { useTelegram } from "./useTelegramHook";
import { useSelector } from "react-redux";
import paths from "../../pages/paths";
import { PreloadImages } from "../../utils/globals";

export const useNavbarHook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tg } = useTelegram();
  const telegramData = useSelector((state) => state.telegram.telegramData);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const isIos = telegramData?.platform === "ios";
  const isDarkScheme = telegramData?.colorScheme === "dark";

  const navItems = [
    { paths: [paths.heroes], src: PreloadImages.NAVBAR_HEROES },

    { paths: [paths.quests], src: PreloadImages.NAVBAR_TASKS },
    {
      paths: [paths.index, paths.hero, paths.giveaways],
      src: PreloadImages.NAVBAR_HOME,
    },
    {
      paths: [paths.market, paths.marketItem],
      src: PreloadImages.NAVBAR_SHOP,
    },
    { paths: [paths.rating], src: PreloadImages.NAVBAR_RATING },
  ];

  const handleNavigate = (path) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(path);
  };

  return {
    location,
    isIos,
    isDarkScheme,
    navItems,
    handleNavigate,
  };
};
