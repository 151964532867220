import React from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import HeroesForQuest from "../../components/ForHeroes/HeroesForQuest/HeroesForQuest";
import cx from "classnames";
import { useQuestHook } from "../../hooks/Pages/useQuestHook";
import QuestInfo from "./QuestInfo";
import QuestActions from "./QuestActions";
import ResultModal from "./ResultModal/ResultModal";

const Quest = () => {
  const {
    isLoading,
    isSelect,
    quest,
    endDate,
    attributeIcons,
    handleRemoveHero,
    selectedHeroes,
    handleStartQuest,
    setSelectedHeroes,
    handleCloseHeroes,
    questChance,
    setQuestChance,
    isChanceLoading,
    isResultModalOpen,
    setIsResultModalOpen,
    levelUpInfo,
    setIsChanceLoading,
    handleOpenHeroesList,
  } = useQuestHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div
        className={cx(styles.wrapper, styles.fadeIn, {
          [styles.overflow]: !isSelect,
        })}
      >
        <QuestInfo
          questChance={questChance}
          quest={quest}
          attributeIcons={attributeIcons}
          endDate={endDate}
          isChanceLoading={isChanceLoading}
        />
        {!isSelect && (
          <QuestActions
            handleStartQuest={handleStartQuest}
            quest={quest}
            selectedHeroes={selectedHeroes}
            handleOpenHeroesList={handleOpenHeroesList}
            handleRemoveHero={handleRemoveHero}
            isChanceLoading={isChanceLoading}
          />
        )}
        {isSelect && (
          <HeroesForQuest
            questId={quest.id}
            questChance={questChance}
            setQuestChance={setQuestChance}
            handleCloseHeroes={handleCloseHeroes}
            selectedHeroes={selectedHeroes}
            setSelectedHeroes={setSelectedHeroes}
            heroesCount={quest.required_heroes_count}
            mainCharacteristic={quest.main_characteristic}
            setIsChanceLoading={setIsChanceLoading}
          />
        )}
      </div>
      {isResultModalOpen && (
        <ResultModal
          quest={quest}
          setIsResultModalOpen={setIsResultModalOpen}
          levelUpInfo={levelUpInfo}
        />
      )}
    </>
  );
};

export default Quest;
