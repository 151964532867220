import React, { memo } from "react";
import { Cell, Checkbox } from "@telegram-apps/telegram-ui";
import AttributesRow from "../AttributesRow/AttributesRow";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import MiniCardImages from "../MiniCard/MiniCardImages";

const HeroCell = React.forwardRef(
  ({ hero, isSelected, onCheckboxChange, isDisabled }, ref) => {
    const { t } = useTranslation();

    return (
      <Cell
        ref={ref}
        onClick={() => onCheckboxChange(hero)}
        className={styles.cell}
        key={hero.id}
        titleBadge={
          <img alt={hero.hero_name} src={hero.class_image_url} width={20} />
        }
        after={
          <Checkbox
            onChange={() => onCheckboxChange(hero)}
            checked={isSelected}
            disabled={isDisabled}
          />
        }
        before={
          <div style={{ height: "100px", width: "50px" }}>
            <MiniCardImages character={hero} />
          </div>
        }
        description={<AttributesRow character={hero} isQuest />}
        subtitle={`${t("Level")}: ${Math.floor(hero.level)}`}
      >
        {hero.hero_name}
      </Cell>
    );
  },
);

export default memo(HeroCell);
