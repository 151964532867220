import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useLoaderHook from "../Layout/useLoaderHook";
import { useTelegram } from "../Layout/useTelegramHook";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import { buyMarketItem, getMarketItems } from "../../services/marketService";
import { getUserWallets } from "../../services/userService";
import paths from "../../pages/paths";
import { useSocket } from "../../utils/socketContext";

export const useMarketHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLoaderHook();
  const { tg } = useTelegram();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { userChannel } = useSocket();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [marketItems, setMarketItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userAmount, setUserAmount] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, type: "" });
  const [activeId, setActiveId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModalItem, setActiveModalItem] = useState(null);
  const [activeItemImage, setActiveItemImage] = useState(null);

  useEffect(() => {
    fetchUserData(dispatch);
    fetchUserWallets();
    getMarketItems()
      .then((data) => {
        setMarketItems(data);
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
    tg.BackButton.hide();
  }, []);

  const fetchUserWallets = async () => {
    try {
      const response = (await getUserWallets()) || [];
      const wallet = response?.find(
        (wallet) => wallet?.balance?.currency === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }
    } catch (error) {
      console.error("Error fetching user wallets:", error);
    }
  };

  const handleBuyMarketItem = (id) => {
    setIsModalOpen(false);
    buyMarketItem(id)
      .then((r) => {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
        setActiveId(r.id);
        showSnackbar("success");
        fetchUserWallets();
      })
      .catch(() => {
        showSnackbar("error");
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      });
  };

  const handleBuyStarMarketItem = (id) => {
    buyMarketItem(id)
      .then((r) => {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
        tg.openTelegramLink(r.payment_url);
      })
      .catch(() => {
        showSnackbar("error");
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      });
  };

  const showSnackbar = (type) => {
    setSnackbar({ open: true, type });
    setTimeout(() => setSnackbar({ open: false, type: "" }), 3000);
  };

  const handleMarketItemClick = (id) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.marketItem, { state: { id } });
  };

  const handleNavigateToInventory = () => {
    navigate(paths.inventory, { state: { from: pathname } });
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
  };

  const handleCardClick = (item) => {
    if (item.status !== "coming_soon") {
      handleMarketItemClick(item.id);
    } else {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
    }
  };

  const handleChipClick = (e, item) => {
    if (item.status === "coming_soon") {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      return;
    }

    e.stopPropagation();
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();

    if (item.price.currency === "STAR") {
      handleBuyStarMarketItem(item.id);
      setActiveItemImage(item.image_url);
    } else {
      setActiveModalItem(item);
      setIsModalOpen(true);
    }
  };

  const handleOpenRoulette = () => {
    navigate(paths.roulette, {
      state: { from: pathname, id: activeId },
    });
  };

  useEffect(() => {
    if (!userChannel) return;

    userChannel.onMessage = (event, payload) => {
      if (payload?.user_market_item_id) {
        setActiveId(payload?.user_market_item_id);
        setIsSuccessModalOpen(true);
      }
      return payload;
    };
  }, [userChannel]);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  return {
    handleMarketItemClick,
    handleBuyMarketItem,
    pathname,
    t,
    marketItems,
    isLoading,
    userAmount,
    snackbar,
    setSnackbar,
    navigate,
    activeId,
    isModalOpen,
    setIsModalOpen,
    activeModalItem,
    setActiveModalItem,
    tg,
    handleNavigateToInventory,
    handleCardClick,
    handleChipClick,
    handleOpenRoulette,
    setIsSuccessModalOpen,
    isSuccessModalOpen,
    activeItemImage,
  };
};
