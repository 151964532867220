// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_roulette__lqGTR {
  height: min-content;
  margin: auto 0;
}

.styles_roulette-pro-regular-prize-item-wrapper__sVZVy {
  height: 100%;
}

.styles_buttonRow__LJXc1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5vh;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.styles_column__m0ggA {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  align-items: center;
  justify-content: center;
}

.styles_heroContainer__VEc98 {
  position: relative;
  height: 90%;
  width: 100%;
  background-color: var(--tg-theme-bg-color);
}

.styles_height__uij1y {
  height: 100%;
}

.styles_image__weCrH {
  width: 22vh;
  height: 16vh;
  border-radius: 12px;
}

.styles_title__Z9TeT {
  color: var(--tg-theme-link-color);
  animation: styles_fadeIn__01r0K 1s ease-in-out;
}

@keyframes styles_fadeInAnimation__a3oa- {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__01r0K {
  opacity: 0;
  animation: styles_fadeInAnimation__a3oa- 1s forwards;
}

.styles_fixedLayout__3qN27 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  text-align: center;
  padding: 16px 16px 0 16px;
}

.styles_question__Qp\\+WS {
  color: var(--tg-theme-link-color);
  cursor: pointer;
}

.styles_caption__1H9dN {
  font-size: 0.6rem;
}

.styles_button__Q0B4V {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Roulette/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,0CAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,iCAAA;EACA,8CAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;EACE,UAAA;EACA,oDAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,yBAAA;AAAF;;AAGA;EACE,iCAAA;EACA,eAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,WAAA;AAAF","sourcesContent":[".roulette {\n  height: min-content;\n  margin: auto 0;\n}\n\n.roulette-pro-regular-prize-item-wrapper {\n  height: 100%;\n}\n\n.buttonRow {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  gap: 5vh;\n  align-items: center;\n  justify-content: center;\n  padding: 16px;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  gap: 2vh;\n  align-items: center;\n  justify-content: center;\n}\n\n.heroContainer {\n  position: relative;\n  height: 90%;\n  width: 100%;\n  background-color: var(--tg-theme-bg-color);\n}\n\n.height {\n  height: 100%;\n}\n\n.image {\n  width: 22vh;\n  height: 16vh;\n  border-radius: 12px;\n}\n\n.title {\n  color: var(--tg-theme-link-color);\n  animation: fadeIn 1s ease-in-out;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.fixedLayout {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 3vh;\n  text-align: center;\n  padding: 16px 16px 0 16px;\n}\n\n.question {\n  color: var(--tg-theme-link-color);\n  cursor: pointer;\n}\n\n.caption {\n  font-size: 0.6rem;\n}\n\n.button {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roulette": `styles_roulette__lqGTR`,
	"roulette-pro-regular-prize-item-wrapper": `styles_roulette-pro-regular-prize-item-wrapper__sVZVy`,
	"buttonRow": `styles_buttonRow__LJXc1`,
	"column": `styles_column__m0ggA`,
	"heroContainer": `styles_heroContainer__VEc98`,
	"height": `styles_height__uij1y`,
	"image": `styles_image__weCrH`,
	"title": `styles_title__Z9TeT`,
	"fadeIn": `styles_fadeIn__01r0K`,
	"fadeInAnimation": `styles_fadeInAnimation__a3oa-`,
	"fixedLayout": `styles_fixedLayout__3qN27`,
	"question": `styles_question__Qp+WS`,
	"caption": `styles_caption__1H9dN`,
	"button": `styles_button__Q0B4V`
};
export default ___CSS_LOADER_EXPORT___;
