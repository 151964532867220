// AppContainer.jsx
import React, { useEffect, useRef, useState } from "react";
import { ConsoleViewer } from "./AppConsoleViewer";
import styles from "./styles.module.scss";
import { InitApp } from "./InitApp";

export const AppContainer = () => {
  const [showConsole, setShowConsole] = useState(false);
  const [pressing, setPressing] = useState(false);
  const timerRef = useRef(null);
  const touchAreaRef = useRef(null);
  const consoleRef = useRef(null); // Реф для консоли

  const handleTouchStart = () => {
    setPressing(true);
    timerRef.current = setTimeout(() => {
      setShowConsole(true);
      setPressing(false);
    }, 2000); // 5000 миллисекунд = 5 секунд
  };

  const handleTouchEnd = () => {
    setPressing(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const handleClickOutside = (event) => {
    if (consoleRef.current && !consoleRef.current.contains(event.target)) {
      setShowConsole(false);
    }
  };

  useEffect(() => {
    const touchArea = touchAreaRef.current;
    if (touchArea) {
      touchArea.addEventListener("touchstart", handleTouchStart);
      touchArea.addEventListener("touchend", handleTouchEnd);
      touchArea.addEventListener("touchcancel", handleTouchEnd);
    }

    return () => {
      if (touchArea) {
        touchArea.removeEventListener("touchstart", handleTouchStart);
        touchArea.removeEventListener("touchend", handleTouchEnd);
        touchArea.removeEventListener("touchcancel", handleTouchEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (showConsole) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [showConsole]);

  return (
    <div className={styles.appContainerWrapper}>
      <InitApp />
      <div
        ref={consoleRef}
        className={`${styles.console} ${showConsole ? styles.show : styles.hide}`}
      >
        <ConsoleViewer />
      </div>
      <div ref={touchAreaRef} className={styles.touchArea}>
        {pressing && (
          <div className={styles.progressBar}>
            <div className={styles.progress}></div>
          </div>
        )}
      </div>
    </div>
  );
};
