// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__qiF3J {
  text-align: center;
  color: var(--tg-theme-link-color);
  animation: styles_fadeIn__kXhcq 1s ease-in-out;
  font-family: Monaco, sans-serif;
  flex-shrink: 0;
  margin-bottom: 24px;
}

.styles_noMargin__Ho8TE {
  margin-bottom: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iCAAA;EACA,8CAAA;EACA,+BAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".title {\n  text-align: center;\n  color: var(--tg-theme-link-color);\n  animation: fadeIn 1s ease-in-out;\n  font-family: Monaco, sans-serif;\n  flex-shrink: 0;\n  margin-bottom: 24px;\n}\n\n.noMargin {\n  margin-bottom: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__qiF3J`,
	"fadeIn": `styles_fadeIn__kXhcq`,
	"noMargin": `styles_noMargin__Ho8TE`
};
export default ___CSS_LOADER_EXPORT___;
