import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFilters } from "../../services/filtersService";
import { useSelector } from "react-redux";
import { useTelegram } from "../Layout/useTelegramHook";

export const useHeroesFilters = (setIsModalOpen) => {
  const { t } = useTranslation();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const [searchParams, setSearchParams] = useSearchParams();

  const [classOptions, setClassOptions] = useState([]);
  const [heroOptions, setHeroOptions] = useState([]);
  const [rarityOptions, setRarityOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    class_ids: searchParams.get("class_ids")
      ? searchParams.get("class_ids").split(",")
      : [],
    hero_ids: searchParams.get("hero_ids")
      ? searchParams.get("hero_ids").split(",")
      : [],
    rarity_level_ids: searchParams.get("rarity_level_ids")
      ? searchParams.get("rarity_level_ids").split(",")
      : [],
  });

  useEffect(() => {
    getFilters()
      .then((filters) => {
        setClassOptions(
          filters.classes.map((cls) => ({ value: cls.id, label: cls.name })),
        );
        setHeroOptions(
          filters.heroes.map((hero) => ({ value: hero.id, label: hero.name })),
        );
        setRarityOptions(
          filters.rarity_levels.map((rarity) => ({
            value: rarity.id,
            label: rarity.name,
          })),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleFilterChange = (filterType, value) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value ? value.map((item) => item.value) : [],
    }));
  };

  const applyFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    Object.entries(filters).forEach(([key, value]) => {
      if (value.length > 0) {
        newSearchParams.set(key, value.join(","));
      } else {
        newSearchParams.delete(key);
      }
    });

    newSearchParams.set("page", "1");

    setSearchParams(newSearchParams);
    setIsModalOpen(false);
  };

  const clearAllFilters = () => {
    setFilters({
      class_ids: [],
      hero_ids: [],
      rarity_level_ids: [],
    });
  };

  return {
    t,
    clearAllFilters,
    applyFilters,
    handleFilterChange,
    isLoading,
    filters,
    classOptions,
    rarityOptions,
    heroOptions,
  };
};
