export const getNonZeroAttributes = (skill, t) => {
  const attributes = {
    agility: skill.agility_total_effect,
    charisma: skill.charisma_total_effect,
    endurance: skill.endurance_total_effect,
    intelligence: skill.intelligence_total_effect,
    luck: skill.luck_total_effect,
    strength: skill.strength_total_effect,
  };

  return Object.entries(attributes)
    .filter(([key, value]) => parseFloat(value) > 0)
    .map(([key, value]) => [t(key), value]);
};
