// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_transactionsWrapper__\\+rQW3 {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
}

.styles_title__TTbR5 {
  text-align: center;
  color: var(--tg-theme-link-color);
  animation: styles_fadeIn__IUd8L 1s ease-in-out;
  font-family: Monaco, sans-serif;
  white-space: pre-line;
  margin-bottom: 16px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.styles_list__W\\+IJT {
  animation: styles_fadeInAnimation__wDpyA 1s forwards;
  opacity: 0;
  width: 100%;
  animation-delay: 0.3s;
  padding: unset;
}

.styles_cell__kYiSa {
  background: var(--tgui--bg_color);
  border-radius: 16px;
  cursor: unset;
}

.styles_cell__kYiSa:hover {
  background: var(--tgui--bg_color);
  opacity: 1;
}

.styles_cellAfter__bakrL {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
}

.styles_amount__akOzd {
  text-align: end;
  white-space: nowrap;
}

@keyframes styles_fadeInAnimation__wDpyA {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__IUd8L {
  opacity: 0;
  animation: styles_fadeInAnimation__wDpyA 1s forwards;
}

.styles_loaderWrapper__SwZSD {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_received__E946W {
  color: var(--tgui--green);
}`, "",{"version":3,"sources":["webpack://./src/pages/Transactions/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,iCAAA;EACA,8CAAA;EACA,+BAAA;EACA,qBAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,oDAAA;EACA,UAAA;EACA,WAAA;EACA,qBAAA;EACA,cAAA;AACF;;AAEA;EACE,iCAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAGA;EACE,iCAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,qBAAA;AAAF;;AAGA;EACE,eAAA;EACA,mBAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE,UAAA;EACA,oDAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;;AAIA;EACE,yBAAA;AADF","sourcesContent":[".transactionsWrapper {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 16px;\n}\n\n.title {\n  text-align: center;\n  color: var(--tg-theme-link-color);\n  animation: fadeIn 1s ease-in-out;\n  font-family: Monaco, sans-serif;\n  white-space: pre-line;\n  margin-bottom: 16px;\n  width: 100%;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.list {\n  animation: fadeInAnimation 1s forwards;\n  opacity: 0;\n  width: 100%;\n  animation-delay: 0.3s;\n  padding: unset;\n}\n\n.cell {\n  background: var(--tgui--bg_color);\n  border-radius: 16px;\n  cursor: unset;\n  //padding: unset;\n}\n\n.cell:hover {\n  background: var(--tgui--bg_color);\n  opacity: 1;\n}\n\n.cellAfter {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  align-items: flex-end;\n}\n\n.amount {\n  text-align: end;\n  white-space: nowrap;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.loaderWrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.received {\n  color: var(--tgui--green);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionsWrapper": `styles_transactionsWrapper__+rQW3`,
	"title": `styles_title__TTbR5`,
	"fadeIn": `styles_fadeIn__IUd8L`,
	"list": `styles_list__W+IJT`,
	"fadeInAnimation": `styles_fadeInAnimation__wDpyA`,
	"cell": `styles_cell__kYiSa`,
	"cellAfter": `styles_cellAfter__bakrL`,
	"amount": `styles_amount__akOzd`,
	"loaderWrapper": `styles_loaderWrapper__SwZSD`,
	"received": `styles_received__E946W`
};
export default ___CSS_LOADER_EXPORT___;
