import React from "react";
import styles from "./styles.module.scss";
import {
  Avatar,
  Caption,
  Cell,
  CircularProgress,
  List,
  Navigation,
  SegmentedControl,
} from "@telegram-apps/telegram-ui";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { useTranslation } from "react-i18next";
import { useGiveawaysHook } from "../../hooks/Pages/useGiveawaysHook";
import { formatAmount } from "../../utils/formatNumbers";
import NoData from "./NoData";
import PageTitle from "../../components/PageTitle/PageTitle";

const Giveaways = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    handleChangeStatus,
    activeStatus,
    isDataLoading,
    page,
    giveawaysData,
    lastGiveawayElementRef,
    handleGoToGiveaway,
    calculateProgress,
  } = useGiveawaysHook();
  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={`${styles.wrapper} ${styles.fadeIn}`}>
      <PageTitle title={"Giveaways"} />
      <SegmentedControl className={`${styles.segmentControl} ${styles.fadeIn}`}>
        <SegmentedControlItem
          className={styles.controlItem}
          onClick={() => handleChangeStatus("active")}
          selected={activeStatus === "active"}
        >
          {t("Active")}
        </SegmentedControlItem>
        <SegmentedControlItem
          className={styles.controlItem}
          onClick={() => handleChangeStatus("completed")}
          selected={activeStatus === "completed"}
        >
          {t("Completed")}
        </SegmentedControlItem>
      </SegmentedControl>
      {isDataLoading && page === 1 && (
        <div className={styles.dataLoader}>
          <LoaderComponent />
        </div>
      )}
      {!isDataLoading && !giveawaysData.length && <NoData />}
      {(!isDataLoading || page > 1) && (
        <List className={`${styles.list} ${styles.fadeIn}`}>
          {giveawaysData.map((item, index) => (
            <Cell
              ref={
                index === giveawaysData.length - 1
                  ? lastGiveawayElementRef
                  : null
              }
              key={item.id}
              onClick={() => handleGoToGiveaway(item.id)}
              className={styles.cell}
              after={
                <div className={styles.row}>
                  {item.status !== "completed" && (
                    <CircularProgress
                      progress={calculateProgress(
                        item.started_at,
                        item.ended_at,
                      )}
                      size="small"
                    />
                  )}
                  <Navigation />
                </div>
              }
              before={<Avatar className={styles.avatar} src={item.image_url} />}
              subhead={
                <Caption level="1" weight="1">
                  {`${formatAmount(item.pool_amount)} ${item.pool_currency}`}
                </Caption>
              }
            >
              <span className={styles.customFont}>{item.title}</span>
            </Cell>
          ))}
        </List>
      )}
    </div>
  );
};

export default Giveaways;
