// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* Стили для контейнера прогресс-бара */
.syles_progressContent__67vL\\+ {
  margin: 15px 70px 0 70px;
  padding: 6px 0;
  z-index: 2;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
}

/* Общие стили для элемента progress */
.syles_progress__wYD3E {
  width: 100%;
  height: 5px;
  appearance: none;
  background-color: var(--toastify-spinner-color);
  border-radius: 10px;
  overflow: hidden;
}

.syles_progress__wYD3E::-webkit-progress-value {
  background-color: var(--tg-theme-link-color);
  border-radius: 10px;
}

.syles_progress__wYD3E::-webkit-progress-bar {
  background-color: var(--toastify-spinner-color);
  border-radius: 10px;
}

.syles_progress__wYD3E::-moz-progress-bar {
  background-color: var(--tg-theme-link-color);
  border-radius: 10px;
}

.syles_progress__wYD3E::-ms-fill {
  background-color: var(--tg-theme-link-color);
  border-radius: 10px;
}

.syles_detailProgress__wSxgS {
  margin: unset;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
}

.syles_popupContent__TNbIJ {
  padding: 16px;
  border-radius: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/HeroCardLevel/syles.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,uCAAA;AACA;EACE,wBAAA;EACA,cAAA;EACA,UAAA;EACA,yBAAA;UAAA,iBAAA;EACA,aAAA;AAEF;;AACA,sCAAA;AACA;EACE,WAAA;EACA,WAAA;EAEA,gBAAA;EACA,+CAAA;EACA,mBAAA;EACA,gBAAA;AAEF;;AAEA;EACE,4CAAA;EACA,mBAAA;AACF;;AAEA;EACE,+CAAA;EACA,mBAAA;AACF;;AAEA;EACE,4CAAA;EACA,mBAAA;AACF;;AAEA;EACE,4CAAA;EACA,mBAAA;AACF;;AAGA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AAAF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,oCAAA;EACA,wCAAA;AADF","sourcesContent":["/* Стили для контейнера прогресс-бара */\n.progressContent {\n  margin: 15px 70px 0 70px;\n  padding: 6px 0;\n  z-index: 2;\n  user-select: none;\n  display: flex;\n}\n\n/* Общие стили для элемента progress */\n.progress {\n  width: 100%;\n  height: 5px;\n  -webkit-appearance: none;\n  appearance: none;\n  background-color: var(--toastify-spinner-color);\n  border-radius: 10px;\n  overflow: hidden;\n}\n\n\n.progress::-webkit-progress-value {\n  background-color: var(--tg-theme-link-color);\n  border-radius: 10px;\n}\n\n.progress::-webkit-progress-bar {\n  background-color: var(--toastify-spinner-color);\n  border-radius: 10px;\n}\n\n.progress::-moz-progress-bar {\n  background-color: var(--tg-theme-link-color);\n  border-radius: 10px;\n}\n\n.progress::-ms-fill {\n  background-color: var(--tg-theme-link-color);\n  border-radius: 10px;\n}\n\n\n.detailProgress {\n  margin: unset;\n  width: 100%;\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n\n\n.popupContent {\n  padding: 16px;\n  border-radius: 16px;\n  color: white;\n  background-color: rgba(0, 0, 0, 0.9);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressContent": `syles_progressContent__67vL+`,
	"progress": `syles_progress__wYD3E`,
	"detailProgress": `syles_detailProgress__wSxgS`,
	"popupContent": `syles_popupContent__TNbIJ`
};
export default ___CSS_LOADER_EXPORT___;
