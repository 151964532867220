// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__qsj9a {
  padding: 10px 0;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.styles_row__OI5u7 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.styles_column__QqHwS {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.styles_avatar__AfiP9 {
  align-self: center;
  background: var(--tgui--bg_color);
}
.styles_avatar__AfiP9 > img {
  object-fit: contain;
}

.styles_noWrap__24gaD {
  white-space: nowrap;
}

@keyframes styles_fadeInAnimation__QaZEB {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__gSOJd {
  opacity: 0;
  animation: styles_fadeInAnimation__QaZEB 1s forwards;
}

.styles_backButton__RLHz5 {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_icon__BE5vR {
  display: flex;
}

.styles_text__CWBZz > span {
  white-space: wrap;
}

.styles_text__CWBZz:active {
  transform: scale(0.95);
  transition: transform 0.3s;
}

.styles_snackbar__TUZl- {
  width: min-content;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/Referral/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,uBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;AACF;;AAEA;EACE,kBAAA;EACA,iCAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;EACE,UAAA;EACA,oDAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAIE;EACE,iBAAA;AADJ;;AAIA;EACE,sBAAA;EACA,0BAAA;AADF;;AAIA;EACE,kBAAA;EACA,mBAAA;AADF","sourcesContent":[".wrapper {\n  padding: 10px 0;\n  border-radius: 8px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.row {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.column {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 4px;\n}\n\n.avatar {\n  align-self: center;\n  background: var(--tgui--bg_color);\n  > img {\n    object-fit: contain;\n  }\n}\n\n.noWrap {\n  white-space: nowrap;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.backButton {\n  position: absolute;\n  top: 10px;\n  left: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.icon {\n  display: flex;\n}\n\n.text {\n  > span {\n    white-space: wrap;\n  }\n}\n.text:active {\n  transform: scale(0.95);\n  transition: transform 0.3s;\n}\n\n.snackbar {\n  width: min-content;\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__qsj9a`,
	"row": `styles_row__OI5u7`,
	"column": `styles_column__QqHwS`,
	"avatar": `styles_avatar__AfiP9`,
	"noWrap": `styles_noWrap__24gaD`,
	"fadeIn": `styles_fadeIn__gSOJd`,
	"fadeInAnimation": `styles_fadeInAnimation__QaZEB`,
	"backButton": `styles_backButton__RLHz5`,
	"icon": `styles_icon__BE5vR`,
	"text": `styles_text__CWBZz`,
	"snackbar": `styles_snackbar__TUZl-`
};
export default ___CSS_LOADER_EXPORT___;
