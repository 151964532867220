import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { getUserQuests } from "../../services/questsService";
import { fetchUserData } from "../../services/registrationService";
import paths from "../../pages/paths";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useSocket } from "../../utils/socketContext";

export const useQuestsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const statusFromQuery = searchParams.get("status") || "new";

  const [activeStatus, setActiveStatus] = useState(statusFromQuery);
  const [quests, setQuests] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [completedQuestId, setCompletedQuestId] = useState("");

  const observer = useRef();

  const fetchQuests = (reset = false) => {
    const questStatus =
      activeStatus === "new"
        ? `failed,created`
        : activeStatus === "active"
          ? "finished,started"
          : "completed";

    getUserQuests(page, 10, questStatus)
      .then((r) => {
        const newQuests = r.data.data;
        setQuests((prevQuests) =>
          reset ? newQuests : [...prevQuests, ...newQuests],
        );
        const paginationInfo = r.data.pagination_info || {};
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => setIsDataLoading(false));
  };

  useEffect(() => {
    fetchUserData(dispatch)
      .then((user) => setUserId(user.id))
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setIsDataLoading(true);
    setQuests([]);
    fetchQuests(true);
  }, [activeStatus]);

  useEffect(() => {
    if (page !== 1) fetchQuests();
  }, [page]);

  const lastQuestElementRef = useCallback(
    (node) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  const handleSetActiveStatus = (status) => {
    setPage(1);
    setActiveStatus(status);
    setSearchParams({ status });
  };

  const navigateToQuest = (id) => {
    navigate(`${paths.quest.replace(":id", id)}`);
  };

  const attributeIcons = {
    strength: AsyncPreloadImages.STRENGTH_ICON,
    agility: AsyncPreloadImages.AGILITY_ICON,
    intelligence: AsyncPreloadImages.INTELLIGENCE_ICON,
    endurance: AsyncPreloadImages.ENDURANCE_ICON,
    charisma: AsyncPreloadImages.CHARISMA_ICON,
  };

  const getColorByStatus = (status) => {
    const colors = {
      failed: "red",
      finished: "yellow",
      created: "cornflowerblue",
      completed: "gray",
      started: "green",
    };

    console.log("status", colors[status]);
    return colors[status] || "black";
  };

  const { joinChannel, channel, leaveChannel } = useSocket();

  useEffect(() => {
    if (userId) joinChannel(userId);
    return () => {
      if (channel) leaveChannel(channel);
    };
  }, [userId, channel]);

  const handleShowSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenCompletedQuest = () => {
    handleCloseSnackbar();
    navigate(`${paths.quest.replace(":id", completedQuestId)}`);
  };

  useEffect(() => {
    if (!channel) return;

    channel.onMessage = (event, payload) => {
      if (payload && payload.quest_id) {
        setCompletedQuestId(payload.quest_id);
        handleShowSnackbar();
      }
      return payload;
    };

    return () => {
      channel.onMessage = null;
    };
  }, [channel]);

  return {
    quests,
    handleSetActiveStatus,
    navigateToQuest,
    attributeIcons,
    lastQuestElementRef,
    isDataLoading,
    isLoading,
    page,
    activeStatus,
    getColorByStatus,
    snackbarOpen,
    handleOpenCompletedQuest,
  };
};
