import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { useEffect, useState } from "react";
import { getUserQuestById } from "../../services/questsService";
import { getHeroes } from "../../services/heroService";
import paths from "../../pages/paths";
import { fetchUserData } from "../../services/registrationService";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import {
  getQuestChance,
  getQuestResult,
  startQuest,
} from "../../services/questService";
import { useSocket } from "../../utils/socketContext";

export const useQuestHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  useTelegramBackButton(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [isSelect, setIsSelect] = useState(false);
  const [quest, setQuest] = useState(null);
  const [selectedHeroes, setSelectedHeroes] = useState([]);
  const [userId, setUserId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [anotherQuestId, setAnotherQuestId] = useState("");
  const [questChance, setQuestChance] = useState(null);
  const [isChanceLoading, setIsChanceLoading] = useState(true);

  const handleCloseHeroes = () => {
    setIsSelect(false);
  };

  const getData = () => {
    if (!isLoading) setIsLoading(true);

    getUserQuestById(id)
      .then((r) => {
        setQuest(r);
        return getHeroes(1, 10, null, null, null, null, r.id);
      })
      .then((response) => {
        const heroes = response?.data?.data || [];
        setSelectedHeroes(heroes);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!id) navigate(paths.quests);
    getData();
  }, [id]);

  useEffect(() => {
    fetchUserData(dispatch).then((user) => setUserId(user.id));
  }, []);

  useEffect(() => {
    if (selectedHeroes.length === quest?.required_heroes_count) {
      if (!isChanceLoading) setIsChanceLoading(true);
      getQuestChance(
        id,
        selectedHeroes.map((hero) => hero.id),
      )
        .then((r) => {
          setQuestChance(r.final_chance.toString());
        })
        .finally(() => setIsChanceLoading(false));
    }
  }, [selectedHeroes]);

  const attributeIcons = {
    strength: AsyncPreloadImages.STRENGTH_ICON,
    agility: AsyncPreloadImages.AGILITY_ICON,
    intelligence: AsyncPreloadImages.INTELLIGENCE_ICON,
    endurance: AsyncPreloadImages.ENDURANCE_ICON,
    charisma: AsyncPreloadImages.CHARISMA_ICON,
  };

  const handleRemoveHero = (heroId) => {
    setSelectedHeroes((prevSelectedHeroes) =>
      prevSelectedHeroes.filter((hero) => hero.id !== heroId),
    );
  };

  const handleStartQuest = () => {
    setIsLoading(true);
    if (quest.status === "finished") {
      getQuestResult(quest.id)
        .then((quest) => {
          setQuest(quest.user_quest);
          console.log(quest);
        })
        .finally(() => setIsLoading(false));
      return;
    }
    startQuest(
      quest.id,
      selectedHeroes.map((elem) => elem.id),
    )
      .then((quest) => setQuest(quest))
      .finally(() => setIsLoading(false));
  };

  const startDate = new Date(quest?.started_at);

  const endDate = new Date(
    startDate.getTime() + quest?.duration_in_seconds * 1000,
  );

  const { joinChannel, channel, leaveChannel } = useSocket();

  useEffect(() => {
    if (userId) joinChannel(userId);
    return () => {
      if (channel) leaveChannel(channel);
    };
  }, [userId, channel]);

  const handleOpenAnotherQuest = () => {
    handleCloseSnackbar();
    setIsSelect(false);
    navigate(`${paths.quest.replace(":id", anotherQuestId)}`);
  };

  useEffect(() => {
    if (!channel) return;

    channel.onMessage = (event, payload) => {
      if (payload && payload.quest_id) {
        if (payload.quest_id === id) {
          getData();
        } else {
          setAnotherQuestId(payload.quest_id);
          handleShowSnackbar();
        }
      }
      return payload;
    };

    return () => {
      channel.onMessage = null;
    };
  }, [channel]);

  const handleShowSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return {
    isLoading,
    isSelect,
    quest,
    endDate,
    attributeIcons,
    handleRemoveHero,
    selectedHeroes,
    setIsSelect,
    handleStartQuest,
    snackbarOpen,
    t,
    handleCloseSnackbar,
    handleOpenAnotherQuest,
    setSelectedHeroes,
    handleCloseHeroes,
    questChance,
    isChanceLoading,
  };
};
