import { axiosInstance } from "./axiosService";

export const startQuest = (id, heroes) => {
  return axiosInstance.post(`/me/quests/${id}/start`, {
    user_hero_ids: heroes,
  });
};
export const getQuestResult = (id) => {
  return axiosInstance.post(`/me/quests/${id}/result`);
};
export const getQuestChance = (id, heroes) => {
  return axiosInstance.post(`/me/quests/${id}/chance`, {
    user_hero_ids: heroes,
  });
};
